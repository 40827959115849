import { Button, IconButton, List, ListItem } from '@mui/material';
import { Col, Row, Drawer } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';


export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            loggedIn: false,
            drawerVisible: false
        };
    }

    componentDidUpdate(prevProps) {


    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }



    render() {

        const { account, loggedIn, drawerVisible } = this.state;

        //create button that goes to login page

        const LoginButton = () => {
            let navigate = useNavigate();
            return (
                <Button variant="link" color="primary" style={{ textTransform: 'none', fontSize: 16, padding: 6 }}
                    onClick={() => {
                        this.setState({ drawerVisible: false })
                        navigate('/signin')
                    }
                    }>
                    Login
                </Button>
            )
        }
        const PricingButton = () => {
            let navigate = useNavigate();
            return (
                <Button variant="link" color="primary" style={{ textTransform: 'none', fontSize: 16, padding: 6 }}
                    onClick={() => {
                        this.setState({ drawerVisible: false })
                        navigate('/pricing')
                    }
                    }>
                    Pricing
                </Button>
            )
        }

        //create signup button that goes to sign in page

        const SignUpButton = () => {
            let navigate = useNavigate();
            return (
                <Button variant="contained" color="primary" style={{ textTransform: 'none', backgroundColor: '#3b5998', color: 'white', fontSize: 16, padding: 6, paddingLeft: 20, paddingRight: 20 }}
                    onClick={() => {
                        this.setState({ drawerVisible: false })
                        navigate('/signup')
                    }}>
                    Sign Up
                </Button>
            )
        }
        const HomeButton = () => {
            let navigate = useNavigate();

            return (
                <Button variant="link" color="primary" display="indline" style={{ padding: 10, textTransform: 'none', color: '#3b5998', fontSize: 16, padding: 6, }}

                    onClick={() => {
                        this.setState({ drawerVisible: false })
                        navigate('/')
                    }}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/cloud-prompt-ba4db.appspot.com/o/images%2F1211af-436d-aae1-87aa-3e2e6d53fdb?alt=media&token=51856184-66d2-41a2-b66a-cd681d620226"
                        style={{ height: 50, width: 50, }} />
                </Button>
            )
        }
        const BetaButton = () => {
            let navigate = useNavigate();

            return (

                <img src="https://firebasestorage.googleapis.com/v0/b/cloud-prompt-ba4db.appspot.com/o/images%2F84f7d0-64a-70f5-3be7-2d34d3cb755?alt=media&token=ddc45b4b-63e3-440b-957a-95f52dfd3153"
                    style={{ height: 40, width: 40, }} />

            )
        }


        return (
            <Row justify="end">
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Row justify="end" gutter={[8, 8]}>
                        <Col span={15} style={{ textAlign: 'left' }}>

                            <HomeButton />
                            <BetaButton />
                        </Col>
                        <Col xs={0} sm={9} style={{ paddingTop: 10 }}>
                            <Row justify="end" gutter={[8, 8]}>

                                <Col >
                                    <PricingButton />
                                </Col>
                                <Col >
                                    <LoginButton />
                                </Col>

                                <Col  >
                                    <SignUpButton />

                                </Col>

                            </Row>
                        </Col>

                        <Col xs={9} sm={0}>
                            <Row justify="end" gutter={[8, 8]}>
                                <Col span={24}  >
                                    <IconButton onClick={() => this.setState({ drawerVisible: true })}>


                                        <MenuIcon />
                                    </IconButton>


                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Drawer title={null} closeIcon={null} header width='50vw' placement="right" onClose={() => this.setState({ drawerVisible: false })} open={drawerVisible}>
                    <List>
                        <ListItem>
                            <LoginButton />
                        </ListItem>
                        <ListItem>
                            <SignUpButton />
                        </ListItem>


                    </List>
                </Drawer>





            </Row>
        );
    }
} 