import React, { useState, useEffect } from 'react';
import { Col, Row, Grid } from 'antd';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged } from "firebase/auth";
import SignedInRouting from './signedInRouting';
import { Button, OutlinedInput, Typography } from '@mui/material';
import Header from './header';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, updateDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import moment from 'moment';

// Configure Firebase.
const config = {
    apiKey: "AIzaSyADDzO6NMGl4Vj_-y0jEb2ZkPIwCJb4PvA",
    authDomain: "cloud-prompt-ba4db.firebaseapp.com",
    projectId: "cloud-prompt-ba4db",
    storageBucket: "cloud-prompt-ba4db.appspot.com",
    messagingSenderId: "202471700064",
    appId: "1:202471700064:web:0519602200b4cf76b5f505",
    measurementId: "G-HF9SK3E5Z0"
    // ...
};


firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],


    callbacks: {
        // Avoid redirects after sign-in.



        signInSuccessWithAuthResult: async (authResult, redirectUrl) => {

            const user = authResult.user;
            const db = getFirestore();
            const docRef = doc(db, 'customers', user.uid)
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                window.location.href = "/";
                
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                await setDoc(doc(db, "customers", user.uid), {
                    email: user.email,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    phoneNumber: user.phoneNumber,
                    emailVerified: user.emailVerified,
                    isAnonymous: user.isAnonymous,
                    uid: user.uid,
                    providerData: user.providerData,
                    creationTime: moment().format('x'),
                    tenantId: user.tenantId,
                }).then(() => {
                    console.log("Document successfully written!");
                    //go to location "/"
                    window.location.href = "/";

                })
            }
            return false;

        }
    },
};

function SignInScreen() {
    return (



        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />

    );
}



export default class SignInPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            user: {
                email: '',
                password: '',
                confirmPassword: '',
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.setState({ type: this.props.type })
        }

    }










    render() {
        const { auth, uid, loading, type, email, password, confirmPassword } = this.state;




        return (
            <Row style={{ minHeight: '100vh' }} >

                <Col span={23}>
                    <Row align="middle">
                        <Col xs={24} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'center' }}>
                            <Typography variant="h4">{type === 'signin' ? "Sign In" : "Sign Up"}</Typography>
                        </Col>



                        <Col span={24} style={{ textAlign: 'center' }}>
                            <SignInScreen />
                        </Col>


                    </Row>
                </Col>

            </Row >
        );



    }
} 