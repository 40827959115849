import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider, Grid, List, ListItem, ListItemText } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, getDocs, collection, where, CircularProgress } from 'firebase/firestore';

export default class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            products: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }


    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const db = getFirestore();
        const docRef = getDocs(collection(db, "products", 'prod_MiURaSSRks7gOw', 'prices'), where('active', ' == ', true));
        docRef.then((querySnapshot) => {
            const products = [];
            querySnapshot.forEach((doc) => {
                products.push({ ...doc.data(), priceId: doc.id });
            });
            this.setState({ products })

        }
        ).catch((error) => {
            console.log("Error getting documents: ", error);
        }
        );
    }

    render() {

        const { account, products, screenWidth } = this.state;

        const SignUpButton = () => {
            let navigate = useNavigate();
            return (
                <Button variant="contained" color="primary" style={{ textTransform: 'none', backgroundColor: '#3b5998', color: 'white', fontSize: 16, padding: 6, paddingLeft: 20, paddingRight: 20 }}
                    onClick={() => {
                        this.setState({ drawerVisible: false })
                        navigate('/signup')
                    }}>
                    Sign Up
                </Button>
            )
        }

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Pricing</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col span={24} style={{ paddingTop: 50 }}>
                            <Grid container spacing={2}>
                                {[...products, { unit_amount: 0, description: 'Free', }].sort((a, b) => a.unit_amount - b.unit_amount).map((product) => {

                                    return (
                                        <Grid item xs={12} sm={4} md={4} lg={4} >
                                            <Paper elevation={4} style={{ padding: 20, textAlign: 'center' }}>

                                                <Typography variant="h3" fontWeight={300} style={{ paddingBottom: 10 }}>${product?.unit_amount / 100}/mo</Typography>
                                                <Typography variant="body1" style={{ paddingBottom: 10 }}>{product?.description}</Typography>
                                                <SignUpButton />

                                                <List >
                                                    <ListItem disablePadding>
                                                        <ListItemText primary={product?.description === 'Basic' ? 'Up to 350 images' : product?.description === 'Pro' ? 'Unlimited images' :
                                                            'This is the free tier and you are limited to 100 photos'} />
                                                    </ListItem>

                                                    <ListItem disablePadding>
                                                        <ListItemText primary={['Basic', 'Pro'].includes(product?.description) && ("Premium support & request features")} />
                                                    </ListItem>

                                                </List>


                                            </Paper>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Col>

                    </Row>
                </Col>

            </Row>
        );
    }
}

