import React from 'react';
import { Col, Row, Grid, Typography } from 'antd';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SignedInRouting from './signedInRouting';
import Routing from './routing';
import { CircularProgress } from '@mui/material';
import { doc, getFirestore, getDoc } from 'firebase/firestore';
import ScrollToTop from 'react-scroll-to-top';

const { useBreakpoint } = Grid;

export default class Router extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            auth: false,
            uid: null,
            screenWidth: null,
            userInfo: {}
        };
    }


    componentDidMount() {
        const auth = getAuth();







        onAuthStateChanged(auth, async (user) => {

            if (user) {

                //get userInfo from 'customers'
                const db = getFirestore();
                const docRef = doc(db, 'customers', user.uid)
                const docSnap = await getDoc(docRef);


                if (docSnap.exists()) {

                    this.setState({ auth: true, uid: user.uid, loading: false, userInfo: { ...docSnap.data() } })
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }


            } else {
                this.setState({ auth: false, uid: null, loading: false })
            }
        });




    }




    setScreen = (s) => {
        const { screenWidth } = this.state;
        if (s !== screenWidth) {
            this.setState({ screenWidth: s })
        }
    }



    checkAuth = () => {
        const auth = getAuth();

        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.setState({ auth: true, uid: user.uid })
            } else {
                this.setState({ auth: false, uid: null })
            }
        });


    }





    render() {
        const { auth, uid, loading, screenWidth, userInfo } = this.state;


        const ScreenSize = () => {
            const screens = useBreakpoint();
            const list = Object.entries(screens)
                .filter(screen => !!screen[1]);
            const listLength = list.length;
            const size = list[listLength - 1];
            if (size !== undefined) {
                this.setScreen(size[0])
            }

            return null;
        }


        return (
            <Row style={{ minHeight: '100vh' }}>
                <Col span={24}>
                    {loading ?
                        <Row style={{ height: '100%' }}>
                            <Col span={24} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Row>
                                    <Col span={24} style={{textAlign: 'center'}} >
                                    <img src="https://firebasestorage.googleapis.com/v0/b/cloud-prompt-ba4db.appspot.com/o/images%2F1211af-436d-aae1-87aa-3e2e6d53fdb?alt=media&token=51856184-66d2-41a2-b66a-cd681d620226"
                                    style={{ height: '10vh', }} />
                                    </Col>
                                    <Col span={24} style={{textAlign: 'center'}} >
                                    <CircularProgress />
                                    </Col>
                                    <Col span={24} style={{textAlign: 'center'}} >
                                    <Typography variant="h6" >Loading...</Typography>
                                    </Col>
                                </Row>
                            
                               

                            </Col>
                           
                        </Row> : auth === true ?
                            <SignedInRouting authChange={this.authChange} screenWidth={screenWidth} uid={uid} userInfo={userInfo} /> :
                            <Routing authChange={this.authChange} screenWidth={screenWidth} checkAuth={this.checkAuth} />}
                </Col>
                <ScreenSize />

                <ScrollToTop smooth color="white" height='20'
                    style={{
                        boxShadow: '1px 2px 3px rgba(0,0,0,0.3)',
                        borderRadius: '50%',
                        width: 50,
                        height: 50,
                        backgroundColor: '#3b5998',
                        fontSize: 10

                    }} />
            </Row>
        );



    }
} 