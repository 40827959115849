
import './App.css';
import { BrowserRouter } from "react-router-dom";
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Router from './components/navigation/router';
import 'antd/dist/antd.css';

const firebaseConfig = {
  apiKey: "AIzaSyADDzO6NMGl4Vj_-y0jEb2ZkPIwCJb4PvA",
  authDomain: "cloud-prompt-ba4db.firebaseapp.com",
  projectId: "cloud-prompt-ba4db",
  storageBucket: "cloud-prompt-ba4db.appspot.com",
  messagingSenderId: "202471700064",
  appId: "1:202471700064:web:0519602200b4cf76b5f505",
  measurementId: "G-HF9SK3E5Z0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {


  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>

  );
}

export default App;
