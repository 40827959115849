import React from 'react';
import { Col, Row, Grid } from 'antd';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SignedInRouting from './signedInRouting';
import { Alert, CircularProgress, Divider, Drawer, Fab, IconButton, Slide, Snackbar, Typography } from '@mui/material';
import Header from './header';
import firebase from "firebase/compat/app";
import "firebase/compat/auth"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Route, Routes, useParams } from 'react-router-dom';
import SignInPage from './signInPage';
import LandingPage from '../public/landingPage';
import Profile from '../public/profile';
import FooterBlock from './footer';
import Terms from '../public/terms';
import Privacy from '../public/privacy';
import AboutPage from '../public/about';
import ScrollToTop from 'react-scroll-to-top';
import RoadMap from '../public/roadMap';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import DownloadTest from '../private/pages/downloadTest';
import Pricing from '../public/pricing';

export default class Routing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileid: '',
            screenWidth: this.props.screenWidth,
            openSnack: false,
            snackType: '',
            snackDescription: '',
            about: null,
            roadMap: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }


    }

    async componentDidMount() {


        const db = getFirestore();
        const docRef = doc(db, "aboutPageInfo", "1");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const info = convertFromRaw(docSnap.data().aboutPageInfo)
            this.setState({ about: info });
            console.log(info)

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
        const docRefRoad = doc(db, "roadmap", "1");
        const docSnapRoad = await getDoc(docRefRoad);
        if (docSnapRoad.exists()) {
            console.log("Document data:", docSnapRoad.data());
            const info = convertFromRaw(docSnapRoad.data().pageInfo)
            this.setState({ roadMap: info });

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }


    }


    closeSnack = () => {
        this.setState({ openSnack: false, snackType: '', snackDescription: '' })
    }
    openSnack = (type, description) => {
        this.setState({ openSnack: true, snackType: type, snackDescription: description })
    }



    render() {
        const { auth, uid, loading, profileid, screenWidth, openSnack, snackType, snackDescription, about, roadMap } = this.state;




        return (
            <Row style={{}}>
                <Col span={23} style={{ padding: 20 }}>
                    <Header /> 
                </Col>
                <Col span={24} style={{ padding: 20 }}>
                    <Row align="middle">

                        <Col span={24} style={{ minHeight: '100vh' }}>
                            <Routes>
                                <Route path="/" element={<LandingPage openSnack={this.openSnack} screenWidth={screenWidth} />} />
                                <Route path="/signin" element={<SignInPage type="signin" />} />
                                <Route path='/signup' element={<SignInPage type="signup" />} />
                                <Route path='/profile/:id' element={<Profile uid={''} profileid={profileid} screenWidth={screenWidth} openSnack={this.openSnack} userInfo={{ uid: 'test' }} />} />
                                <Route path='/terms' element={<Terms />} />
                                <Route path='/privacy' element={<Privacy />} />
                                <Route path="/about" element={<AboutPage type="none" about={about} />} />
                                <Route path="*" element={<Typography variant="h1">404</Typography>} />
                                <Route path="/roadmap" element={<RoadMap type="none" pageInfo={roadMap} />} />
                                <Route path="/download" element={<DownloadTest type="none" pageInfo={roadMap} />} />
                                <Route path="/pricing" element={<Pricing type="none" screenWidth={screenWidth} />} />

                            </Routes>
                        </Col>

                    </Row>
                </Col>
                <Col span={23} style={{ height: 200, paddingTop: 30 }}>
                    <Row>
                      
                        <Col span={24}>
                            <FooterBlock />
                        </Col>
                    </Row>
                </Col>
                <Snackbar open={openSnack} autoHideDuration={3000} onClose={this.closeSnack} >
                    <Alert severity={snackType} variant="filled">
                        {snackDescription}
                    </Alert>
                </Snackbar>



            </Row>
        );



    }
} 