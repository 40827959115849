import { Button, CardActions, CardContent, CircularProgress, Grid, IconButton, Paper, Slider, Switch, Typography } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';


import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';


import { DeleteForever, DownloadingOutlined, Favorite, FavoriteBorder, KeyboardArrowLeft, KeyboardArrowRight, RemoveRedEye, } from '@mui/icons-material';
import axios from 'axios';
import FileSaver from 'file-saver';
import { Configure, Highlight, InstantSearch, SearchBox, useInfiniteHits } from 'react-instantsearch-hooks-web';
import { useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { getFirestore, arrayUnion, arrayRemove, updateDoc, doc, deleteDoc, setDoc, getDoc, getDocs, collection, where, query } from 'firebase/firestore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSwipeable } from 'react-swipeable';

import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import * as htmlToImage from 'html-to-image';
import SortableGalleryPhotos from '../pages/galleryComponents/sortableGallery';
import CollectionCreate from './collectionCreate';
import CollectionView from './collectionView';
import { getAuth } from 'firebase/auth';
import { Sticky, StickyContainer } from 'react-sticky';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

var moment = require('moment-timezone');






const searchClient = algoliasearch('6VAIIOAHBD', '7c4faabcd334a04d445b6d99261ea04e');

function CustomInfiniteHits(props) {
    const { hits,
        currentPageHits,
        results,
        isFirstPage,
        isLastPage,
        showPrevious,
        showMore,
        sendEvent, } = useInfiniteHits(props);

    const [currentCard, setCurrentCard] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [collageOpen, setCollageOpen] = useState(false);
    const [likedImages, setLikedImages] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [profileid, setProfileid] = useState(null);
    const [collagePhotos, setCollagePhotos] = useState([]);
    const [collageLayout, setCollageLayout] = useState([1, 1]);
    const [cardIndex, setCardIndex] = useState(0);
    const [initial, setInitial] = useState(true);
    const [preferencesModal, setPreferencesModal] = useState(false);

    const [hitList, setHits] = useState(hits)

    useEffect(() => {
        setLikedImages(props.userInfo?.likedImages || []);

        setCollageOpen(props.isCollageOpen)


        if (hits !== hitList && initial === true) {
            setHits(hits)
            setInitial(false)
        }
    }, [props, hits, props.isCollageOpen]);




    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setCollageOpen(false);


    };

    const setCurrentCardFunc = (card) => {
        setCurrentCard(card);
        console.log(card)
        showModal();

    }

    //function to get new card 
    function getNewCard(type) {
        //find index of current card
        const index = hitList.findIndex(x => x.objectID === currentCard.objectID)
        //get new index based on type, but cannot be lower than 0, or bigger than hits.length
        const newIndex = (index + (type === 'prev' ? -1 : 1)) <= 0 ? 0 : (index + (type === 'prev' ? -1 : 1)) >= hitList.length ? hitList.length - 1 : (index + (type === 'prev' ? -1 : 1))
        //set new card
        console.log(newIndex)
        setCurrentCard({ ...hitList[newIndex], index: newIndex })
        setCardIndex(newIndex)
        if (!props.isAuthedUser) {
            updateViews({ ...hitList[newIndex], index: newIndex })
        }


    }




    const UpdateCard = ({ card, updateHits }) => {
        const db = getFirestore();

        const currentCard = card;

        return (
            <IconButton aria-label="add to favorites"
                onClick={async () => {
                    //get current user
                    const user = props.userInfo;
                    const includes = likedImages.includes(currentCard?.objectID)

                    const docRef = doc(db, 'customers', user.uid)

                    if (includes === false) {
                        //add 1 like to current card and set it
                        setCurrentCard({ ...currentCard, likes: currentCard?.likes + 1 })
                        //update users liked items
                        setLikedImages([...likedImages, currentCard?.objectID])
                        updateHits({ ...currentCard, likes: currentCard?.likes + 1 })
                    }
                    else {
                        setCurrentCard({ ...currentCard, likes: currentCard?.likes - 1 })
                        const filteredLikedImages = likedImages.filter((f) => f !== currentCard?.objectID)
                        setLikedImages([...filteredLikedImages])
                        updateHits({ ...currentCard, likes: currentCard?.likes - 1 })
                    }


                    await updateDoc(docRef, {
                        likedImages: includes === false ? arrayUnion(currentCard?.objectID) : arrayRemove(currentCard?.objectID)
                    }).then(async () => {
                        //add 1 like to the item
                        const docRef = doc(db, 'items', currentCard?.objectID);
                        await updateDoc(docRef, {
                            likes: includes === false ? currentCard?.likes + 1 : currentCard?.likes - 1
                        })
                    }).then(() => {

                        var time = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");

                        const type = includes === false ? 'like' : 'unlike';
                        const key = uuid();
                        const itemkey = currentCard.objectID;
                        const item = {
                            time: `'${time}'`,
                            key: `'${key}'`,
                            type: `'${type}'`,
                            itemkey: `'${itemkey}'`,
                            userkey: `'${user.uid}'`


                        }

                        const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
                        const hourlyString = hourlyVals.join();


                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                            raw: `INSERT INTO promptsss_likes VALUES ${hourlyString}; `
                        }).then(async (response) => {

                        }).catch((error) => {
                            console.log(error)
                        })
                    })
                }}


            >
                {likedImages.includes(currentCard?.objectID) ? <Favorite fontSize='small' style={{ fill: '#d36175' }} /> : <FavoriteBorder fontSize='small' />}
            </IconButton >
        )

    }





    function updateHits(card) {

        const index = hitList.findIndex(x => x.objectID === card.objectID)
        const newHits = [...hitList]
        newHits[index] = card;
        setHits(newHits)
    }

    const UserButton = ({ hit }) => {
        let navigate = useNavigate();
        let { id } = useParams();

        if (id === hit?.user?.uid) {
            return (
                <Button variant='contained' style={{
                    padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 4, boxShadow: '2px 4px 3px 3px rgba(0,0,0,0,2)',
                    color: 'white', textTransform: 'none',
                    backgroundColor: '#7388cc',
                }}
                    onClick={() => {
                        navigate(`/profile/${hit?.creator}`)
                    }}>
                    <Typography variant="body2" fontSize={10} fontWeight={700}
                        style={{
                            color: 'white'
                        }}>
                        {`${hit.userName?.substring(0, 10)}${hit.userName?.length > 10 ? '...' : ''}`}
                    </Typography></Button>
            )
        }
        else {
            return null
        }
    }


    const DeleteButton = ({ hit }) => {

        return (
            <IconButton aria-label="delete" onClick={() => {
                const db = getFirestore();
                const docRef = doc(db, 'collections', hit.objectID);
                deleteDoc(docRef).then(() => {

                }
                ).then(() => {
                    const newHits = hitList.filter((f) => f.objectID !== hit.objectID)
                    setHits(newHits)
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                }
                );
            }}>
                <DeleteForever />
            </IconButton>

        )

    }

    const ChangePublicSwitch = ({ hit }) => {

        return (
            <Switch checked={hit?.public} onChange={(e) => {

                const index = hitList.findIndex(x => x.objectID === hit.objectID)
                const newHits = [...hitList]
                newHits[index] = { ...newHits[index], public: e.target.checked };
                setHits(newHits)

                const db = getFirestore();
                const docRef = doc(db, 'collections', hit.objectID);
                updateDoc(docRef, {
                    public: e.target.checked
                }).then(() => {

                }
                ).catch((error) => {
                    console.error("Error updating document: ", error);
                }
                );

            }} />
        )

    }
    const PublicText = ({ hit }) => {

        return (
            <Typography variant="body2" color="rgba(0,0,0,0.8)" fontWeight={500} style={{ paddingLeft: 20 }}>
                Public
            </Typography>
        )

    }


    const handlers = useSwipeable({

        onSwipedLeft: (eventData) => {
            getNewCard('next')
        },
        onSwipedRight: (eventData) => {
            getNewCard('prev')
        }

    });








    async function updateViews(hit, index) {
        setCurrentCardFunc({ ...hit, index, views: hit.views + 1 })
        const user = props.userInfo;
        const db = getFirestore();
        const currentCard = hit;

        updateHits({ ...currentCard, views: currentCard?.views + 1 })

        const docRef = doc(db, 'collections', currentCard?.objectID);
        await updateDoc(docRef, {
            views: currentCard?.views + 1
        }).then(() => {

            var time = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");

            const type = 'view';
            const key = uuid();
            const itemkey = currentCard.objectID;
            const viewUser = user === undefined ? 'anonymous' : user.uid;
            const item = {
                time: `'${time}'`,
                key: `'${key}'`,
                type: `'${type}'`,
                itemkey: `'${itemkey}'`,
                userkey: `'${viewUser}'`


            }

            const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();


            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `INSERT INTO promptsss_views VALUES ${hourlyString}; `
            }).then(async (response) => {

            }).catch((error) => {
                console.log(error)
            })
        })
    }



    return (
        <Grid container spacing={2} >

            {hitList.map((hit, index) => {
                const hitKey = `${hit.key}`;
                return (

                    <Grid item xs={12 / props.gridSize}>




                        <Paper elevation={4} sx={{
                            // some styles
                            ":hover": {
                                boxShadow: 10,
                                //move the card up slowly

                                transform: "translateY(-5px)",
                                transition: "all 0.3s ease-in-out",

                            },
                        }}
                        >



                            <CardContent style={{ cursor: 'pointer', padding: props.screenWidth !== 'xs' ? 10 : 5, paddingBottom: 0, }} sx={{ paddingBottom: 0 }}
                                onClick={async () => {
                                    setCardIndex(index)
                                    setCurrentCardFunc({ ...hit, index, })
                                    console.log(props)
                                    if (!props.isAuthedUser) {
                                        updateViews(hit, index)
                                    }


                                }} >



                                <img src={hit.url} style={{ maxWidth: '100%', }} alt='alt' />
                                <Typography variant="body2" color="rgba(0,0,0,0.8)" fontSize={props.screenWidth !== 'xs' ? 13 : 10} fontWeight={500} style={{ paddingTop: 10 }}>
                                    <Highlight attribute="name" hit={hit} />
                                </Typography>

                            </CardContent>
                            <CardActions style={{ paddingTop: 0, paddingBottom: 0 }} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                {props.isLoggedIn === true ? <UpdateCard card={hit} updateHits={updateHits} /> : <FavoriteBorder fontSize='small' style={{ cursor: 'pointer', }} onClick={() => {
                                    props.openSnack('warning', 'Please login to favorite prompts')
                                }} />}
                                <Typography variant="body2" color="rgba(0,0,0,0.8)" fontSize={props.screenWidth !== 'xs' ? 12 : 10} fontWeight={500}>
                                    {hit.likes}
                                </Typography>
                                <IconButton aria-label="share" style={{ marginLeft: 20 }}>
                                    <RemoveRedEye fontSize='small' />
                                </IconButton>

                                <Typography variant="body2" fontSize={props.screenWidth !== 'xs' ? 12 : 10} color="rgba(0,0,0,0.8)" fontWeight={500} style={{ paddingRight: 20 }}>
                                    {hit.views}
                                </Typography>




                            </CardActions>




                            {props.isAuthedUser === true && props.type === 'my' && (<CardActions disableSpacing style={{ paddingTop: 0, paddingBottom: 0 }} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <DeleteButton hit={hit} />


                                <PublicText hit={hit} />
                                <ChangePublicSwitch hit={hit} />

                            </CardActions>)}

                        </Paper>



                    </Grid>

                )
            })}

            <Modal title={null} open={isModalOpen} footer={null} bodyStyle={{ padding: 20 }} style={{ fontFamily: 'Inter' }} onCancel={handleCancel} width={'85%'} >
                <Row type="flex"
                    style={{}}

                >

                    <Col xs={12} style={{ paddingTop: 10 }}>
                        {props.isAuthedUser === false && props.type === 'my' && (<CardActions  >
                            {props.isLoggedIn === true ? <UpdateCard card={currentCard} updateHits={updateHits} /> : <FavoriteBorder fontSize='small' style={{ cursor: 'pointer', }} onClick={() => {
                                props.openSnack('warning', 'Please login to favorite prompts')
                            }} />}
                            <Typography variant="body2" color="rgba(0,0,0,0.8)" fontWeight={500}>
                                {currentCard?.likes}
                            </Typography>


                            <IconButton aria-label="share" style={{ paddingLeft: 20 }}>
                                <RemoveRedEye fontSize='small' />
                            </IconButton>
                            <Typography variant="body2" color="rgba(0,0,0,0.8)" fontWeight={500}>
                                {currentCard?.views}
                            </Typography>

                        </CardActions>)}
                    </Col>

                    <Col xs={12} style={{ textAlign: 'left', position: 'relative', padding: 0, zIndex: 2 }}>
                        <Row gutter={[8, 8]}>


                            {cardIndex !== 0 && (
                                <Col>
                                    <Button style={{
                                        fontFamily: 'Inter', textTransform: 'none', backgroundColor: '#3b5998',
                                        color: 'white', fontWeight: 700
                                    }}
                                        _active={{
                                            bg: 'gray.800',
                                            transform: 'scale(0.95)'
                                        }}
                                        onClick={() => {
                                            getNewCard('prev')

                                        }}>
                                        Previous
                                    </Button>
                                </Col>

                            )}
                            {!isLastPage && cardIndex + 1 === hitList.length ?

                                <Col><Button size="small" style={{ textTransform: 'none', fontFamily: 'Inter' }} onClick={() => {


                                    showMore();

                                }}>
                                    Show More
                                </Button></Col>
                                :
                                isLastPage && cardIndex + 1 === hitList.length ?

                                    null

                                    :
                                    <Col><Button style={{
                                        fontFamily: 'Inter', textTransform: 'none', backgroundColor: '#3b5998',
                                        color: 'white', fontWeight: 700
                                    }}
                                        _active={{
                                            bg: 'gray.800',
                                            transform: 'scale(0.95)'
                                        }} onClick={() => {
                                            getNewCard('next')
                                        }}>
                                        Next
                                    </Button></Col>



                            }
                        </Row>
                    </Col>

                    <Col xs={24} style={{}} >
                        <CollectionView {...props} item={currentCard} openSnack={props.openSnack} closeModal={handleCancel} screenWidth={props.screenWidth}
                            uid={props.uid} userInfo={props.userInfo} updateHits={(currentCard) => {
                                console.log(currentCard)
                                updateHits({ ...currentCard, views: currentCard?.views + 0 })
                            }} />
                    </Col>











                </Row>


            </Modal>





            {!isLastPage && (<Grid item xs={12} >

                <Button style={{ fontFamily: 'Inter', textTransform: 'none', width: '100%', height: '100%', borderRadius: 0, backgroundColor: '#f5f5f5', color: '#000000', fontWeight: 700 }}
                    onClick={() => {
                        showMore()
                    }}>Show More</Button>

            </Grid>)}
            {isLastPage && (<Grid item xs={12} style={{ textAlign: 'center' }} >

                <Typography style={{ fontFamily: 'Inter', textTransform: 'none', width: '100%', height: '100%', borderRadius: 0, backgroundColor: '#f5f5f5', color: '#000000', fontWeight: 700, textAlign: 'center' }}>
                    End of the line sorry! No more collections.
                </Typography>

            </Grid>)}


        </Grid >

    )


}



function Search(props) {
    const { id } = useParams();
    const filters = props.type === 'main' ? 'public:true' : props.isAuthedUser === false ? `public:true AND creator:${id}` : `creator:${id}`;

    console.log(filters)
    const [preferencesModalOpen, setPreferencesModalOpen] = useState(false);

    const [gridSize, setGridSize] = useState(props.screenWidth === 'xs' ? 2 : 4)
    useEffect(() => {
        if (props.screenWidth === 'xs') {
            setGridSize(2)
        } else if (props.screenWidth === 'sm') {
            setGridSize(3)
        } else {
            setGridSize(4)
        }
    }, [props.screenWidth]) // eslint-disable-line react-hooks/exhaustive-deps

    console.log(props)



    return (
        <StickyContainer>
            {/* Other elements can be in between `StickyContainer` and `Sticky`,
    but certain styles can break the positioning logic used. */}
            <InstantSearch
                indexName={props.index}
                searchClient={searchClient}
                stalledSearchDelay={200}
            >

                <Row style={{}}>
                    {/* <Col xs={0} sm={4} style={{ paddingRight: 30 }}>
                    <Typography variant="h6" style={{}}>Filters</Typography>
                    <Divider />
                    <RefinementList attribute="maker" style={{ fontFamily: 'Inter', paddingTop: 10 }} />
                </Col> */}
                    <Col xs={24} style={{}}>
                        <Row align='middle'>
                            <Col xs={20} sm={22}>
                                <Sticky topOffset={80}>
                                    {({
                                        style,

                                        // the following are also available but unused in this example
                                        isSticky,
                                        wasSticky,
                                        distanceFromTop,
                                        distanceFromBottom,
                                        calculatedHeight
                                    }) => (
                                        <header style={{ ...style, paddingTop: 10, zIndex: 100 }}>
                                            <SearchBox
                                                searchAsYouType={true}
                                                autoFocus={false}
                                            />
                                        </header>
                                    )}
                                </Sticky>
                            </Col>

                            <Col xs={4} sm={2}>
                                <IconButton style={{ float: 'right' }} onClick={() => { setPreferencesModalOpen(true) }}>
                                    <MoreHorizIcon />
                                </IconButton>

                            </Col>

                        </Row>

                        <Modal title={null} open={preferencesModalOpen} footer={null} bodyStyle={{ padding: 20 }} style={{ fontFamily: 'Inter', padding: 0 }} onCancel={() => { setPreferencesModalOpen(false) }}
                            width={props.screenWidth === 'xs' ? '75%' : '50%'} >
                            <Row>
                                <Col span={6}>
                                    <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10, textAlign: 'center' }}>Grid</Typography>
                                </Col>
                                <Col span={18}>
                                    <Slider
                                        defaultValue={gridSize}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        marks
                                        min={1}
                                        max={6}
                                        onChange={(e, value) => { setGridSize(value) }}
                                    />
                                </Col>
                            </Row>
                        </Modal>



                        <Configure

                            hitsPerPage={48}

                            analytics={false}
                            filters={filters}

                        />
                        <Col xs={24} style={{}}>

                            <Row style={{ paddingTop: 15 }}>
                                <CustomInfiniteHits {...props} openSnack={props.openSnack} updateCollagePhotos={props.updateCollagePhotos} gridSize={gridSize}
                                    screenWidth={props.screenWidth} />
                            </Row>
                        </Col>

                    </Col>
                </Row>



            </InstantSearch>
        </StickyContainer>
    );
}


export default class CollectionsGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            uid: this.props.uid,
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            card: null,
            collagePhotos: [],
            isCollageOpen: false,
            openModal: false,
            isLoggedIn: false,
            isAuthedUser: this.props.isAuthedUser,
            profileid: null,
            index: this.props.index,
            tools: this.props.tools,
            imagesLimit: 2,
            currentImages: 0
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.isAuthedUser !== prevProps.isAuthedUser) {
            this.setState({ isAuthedUser: this.props.isAuthedUser })
        }

    }
    async componentDidMount() {
        console.log('Loading')
        const auth = getAuth();

        if (auth.currentUser !== null) {
            this.setState({ isLoggedIn: true })

            const auth = getAuth();
            const user = auth.currentUser;

            this.setState({ user })
            const db = getFirestore();
            const activeRef = getDocs(collection(db, "customers", this.state.uid, 'subscriptions'), where("status", "in", ["trialing", "active"]));
            const q = query(collection(db, "items"), where("creator", "==", this.state.uid));
            const querySnapshot = await getDocs(q);
            const images = []
            console.log(querySnapshot.size)

            this.setState({ currentImages: querySnapshot.size })

            activeRef.then((querySnapshot) => {
                querySnapshot.forEach(async (snap) => {

                    console.log(snap.data())
                    const subscription = snap.data();
                    const priceId = subscription.items[0].price.id;
                    const priceData = (await getDoc(subscription.price)).data();
                    const item = { ...subscription, id: snap.id, metadata: priceData.metadata, priceId: priceId }
                    this.setState({ activeSubscription: item, priceId, })



                    const imagesLimit = priceId === 'price_1Lz3SNKNkNgX2BexPg4EgZC1' ? 350 :
                        priceId === 'price_1Lz3SNKNkNgX2BextzRvIg6F' ? 99999 : 100;
                    console.log(imagesLimit)
                    this.setState({ imagesLimit })

                });
            }
            ).catch((error) => {
                console.log("Error getting documents: ", error);
            }
            );
        }
        else {
            this.setState({ isLoggedIn: false })
        }

    }

    setCurrentCard = (card) => {
        this.setState({ currentCard: card })

    }

    updateCollagePhotos = (photos) => {
        this.setState({ collagePhotos: photos })
    }

    closeModal = () => {
        this.setState({ openModal: false })
    }

    render() {

        const { uid, type, screenWidth, userInfo, collagePhotos, isCollageOpen, openModal, isLoggedIn, index, tools, filter, isAuthedUser, currentImages, imagesLimit } = this.state;


        console.log(isAuthedUser)



        return (
            <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : filter === 'collections' ? 0 : 30, paddingTop: 20, paddingBottom: 30, paddingRight: screenWidth === 'xs' ? 0 : 30, }}>
                <Row gutter={[0, 0]}>

                    {isAuthedUser && (<Col xs={24} sm={24} style={{ textAlign: 'right', paddingBottom: 10 }}>
                        <Button style={{
                            fontFamily: 'Inter', textTransform: 'none', backgroundColor: '#3b5998',
                            color: 'white', fontWeight: 700
                        }}
                            _active={{
                                bg: 'gray.800',
                                transform: 'scale(0.95)'
                            }} onClick={() => {
                                this.setState({ openModal: true })
                            }}
                        >Create Collection</Button>
                    </Col>)}
                    <Col span={24}>
                        <Search uid={uid} type={type} setCurrentCard={this.setCurrentCard} screenWidth={screenWidth} userInfo={userInfo} isLoggedIn={isLoggedIn} isAuthedUser={isAuthedUser}
                            openSnack={this.props.openSnack} isCollageOpen={isCollageOpen} index={index} tools={tools} filter={filter}

                        />
                    </Col>
                </Row>



                <Modal title={null} open={openModal} footer={null} bodyStyle={{ padding: 20 }} style={{ fontFamily: 'Inter' }} onCancel={this.closeModal} width={'85%'} >
                    <Row type="flex"
                        style={{ alignItems: 'center' }}
                        justify="center"
                    >



                        <CollectionCreate uid={uid} userInfo={userInfo} screenWidth={screenWidth} closeModal={this.closeModal} openSnack={this.props.openSnack}
                            currentImages={currentImages} imagesLimit={imagesLimit}
                        />





                    </Row>


                </Modal>


            </Col>

        );
    }
} 