import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider, Select, MenuItem, Box, CircularProgress, IconButton, Autocomplete, TextField, Switch, TextareaAutosize } from '@mui/material';
import { fullMobile } from './util';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, getDoc, updateDoc, setDoc, arrayUnion, query, collectionGroup, getDocs, collection, where, onSnapshot } from 'firebase/firestore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { DownloadingOutlined, Favorite, HearingOutlined, RemoveRedEye } from '@mui/icons-material';

import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { useNavigate, useNavigation } from 'react-router-dom';
import Resizer from "react-image-file-resizer";
import uuid from 'react-uuid';
import { getAuth } from 'firebase/auth';
import FileSaver, { saveAs } from 'file-saver';
export default class Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            uid: this.props.uid,
            account: this.props.account,
            userInfo: this.props.userInfo,
            user: {},
            item: {
                prompt: '',
                name: '',
                description: '',
                maker: '',
                photoURL: '',
                public: false,
                images: [],
                tags: [],
                views: 0,
                likes: 0,
            },
            lightOpen: false,
            lightIndex: 0,
            isDragActive: false,
            addingImages: false,
            downloading: false,
            progressBars: {},
            activeSubscription: {},
            photoLimit: 5,
            imagesLimit: 100,
            currentImages: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })

        }

    }
    async componentDidMount() {
        window.scrollTo(0, 0)

        const auth = getAuth();
        const user = auth.currentUser;

        this.setState({ user })
        const db = getFirestore();
        const activeRef = getDocs(collection(db, "customers", this.state.uid, 'subscriptions'), where("status", "in", ["trialing", "active"]));
        const q = query(collection(db, "items"), where("creator", "==", this.state.uid));
        const querySnapshot = await getDocs(q);
        const images = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            images.push(doc.data())
        });

        this.setState({ currentImages: images.length })

        activeRef.then((querySnapshot) => {
            querySnapshot.forEach(async (snap) => {


                const subscription = snap.data();
                const priceId = subscription.items[0].price.id;
                const priceData = (await getDoc(subscription.price)).data();
                const item = { ...subscription, id: snap.id, metadata: priceData.metadata, priceId: priceId }
                this.setState({ activeSubscription: item, priceId, })



                const imagesLimit = priceId === 'price_1Lz3SNKNkNgX2BexPg4EgZC1' ? 350 :
                    priceId === 'price_1Lz3SNKNkNgX2BextzRvIg6F' ? 99999 : 100;

                this.setState({ imagesLimit })

            });
        }
        ).catch((error) => {
            console.log("Error getting documents: ", error);
        }
        );






    }

    handleDragEnter = () => {
        this.setState({ isDragActive: true });
    };

    handleDragExit = () => {
        this.setState({ isDragActive: false });

    };

    updateItem = (boo, val) => {
        let item = this.state.item;
        item[boo] = val;
        this.setState({ item: item });

    }

    deletePhoto = (imageKey) => {
        let item = this.state.item;
        let images = item.images;
        const filteredImages = images.filter(image => image.key !== imageKey);
        item.images = filteredImages;
        this.setState({ item: item });
    }

    downloadPhoto = (imageKey) => {
        const item = this.state.item;
        const images = item.images;
        const index = images.findIndex(image => image.key === imageKey);
        const image = images[index];
        const url = image.downloadURL;

        saveAs(url, image.name) // Put your image url here.

    }

    updateImage = (index, boo, val) => {
        let item = this.state.item;
        let images = item.images;
        images[index][boo] = val;
        item.images = images;
        this.setState({ item: item });
    }

    updateImageByKey = (key, boo, val) => {
        let item = this.state.item;
        let images = item.images;
        const index = images.findIndex(image => image.key === key);
        images[index][boo] = val;
        item.images = images;
        this.setState({ item: item });
    }

    getThumbnailUrl = (image) => {
        const storage = getStorage();
        const storageRef = ref(storage, image.thumbnailPath);
        getDownloadURL(storageRef).then((url) => {
            this.updateImageByKey(image.key, 'thumbnailURL', url);
            this.getThumbnailUrl1(image);

        }).catch((error) => {
            this.getThumbnailUrl(image);

        });
    }
    getThumbnailUrl1 = (image) => {
        const storage = getStorage();
        const storageRef = ref(storage, image.thumbnailPath1);
        getDownloadURL(storageRef).then((url) => {
            this.updateImageByKey(image.key, 'thumbnailURL1', url);
            this.updateImageByKey(image.key, 'finalized', 'finalized');

        }).catch((error) => {
            this.getThumbnailUrl1(image);

        });
    }



    render() {

        const { account, prompt, isDragActive, maker, progressBars, data, item, lightOpen, lightIndex, uid, addingImages, imagesLimit, currentImages, userInfo, user, downloading, activeSubscription } = this.state;

        const tags = [
            { value: '3d', label: '3D' },
            { value: 'animation', label: 'Animation' },
            { value: 'art', label: 'Art' },
            { value: 'audio', label: 'Audio' },
            { value: 'code', label: 'Code' },
            { value: 'design', label: 'Design' },
            { value: 'game', label: 'Game' },
            { value: 'photo', label: 'Photo' },
            { value: 'video', label: 'Video' },
            { value: 'accessory', label: 'Accessory' },
            { value: 'apparel', label: 'Apparel' },
            { value: 'animals', label: 'Animals' },
            { value: 'architecture', label: 'Architecture' },
            { value: 'anime', label: 'Anime' },
            { value: 'buildings', label: 'Buildings' },
            { value: 'cartoon', label: 'Cartoon' },
            { value: 'clay', label: 'Clay' },
            { value: 'comics', label: 'Comics' },
            { value: 'nature', label: 'Nature' },
            { value: 'people', label: 'People' },
            { value: 'sculpture', label: 'Sculpture' },
            { value: 'space', label: 'Space' },
            { value: 'sports', label: 'Sports' },
            { value: 'vehicles', label: 'Vehicles' },
            { value: 'drawing', label: 'Drawing' },
            { value: 'painting', label: 'Painting' },
            { value: 'photography', label: 'Photography' },
            { value: 'printmaking', label: 'Printmaking' },
            { value: 'illustration', label: 'Illustration' },
            { value: 'fantasy', label: 'Fantasy' },
            { value: 'food', label: 'Food' },
            { value: 'furniture', label: 'Furniture' },
            { value: 'gadgets', label: 'Gadgets' },
            { value: 'gaming', label: 'Gaming' },
            { value: 'graphic', label: 'Graphic' },
            { value: 'handmade', label: 'Handmade' },
            { value: 'home', label: 'Home' },
            { value: 'jewelry', label: 'Jewelry' },
            { value: 'logos', label: 'Logos' },
            { value: 'mockups', label: 'Mockups' },
            { value: 'monogram', label: 'Monogram' },
            { value: 'holidays', label: 'Holidays' },
        ]
        const makers = [
            { value: 'DALL-E', label: 'DALL-E' },
            { value: 'Midjourney', label: 'Midjourney' },
            { value: 'Stable Diffusion', label: 'Stable Diffusion' },
            { value: 'Craiyon', label: 'Craiyon' },
            { value: 'Night Cafe', label: 'Night Cafe' },
            { value: 'Jasper Art', label: 'Jasper Art' },
            { value: 'Playground AI', label: 'Playground AI' },
            { value: 'Hugging Face', label: 'Hugging Face' },


        ]



        const AddImagesButton = () => {
            let navigate = useNavigate();

            const images = item.images;
            const checkImages = images.filter(image => ['uploading', 'uploaded'].includes(image.finalized));

            //check if prompt is greater than 0
            const checkPrompt = item.prompt.length > 0;
            const checkImageLength = images.length > 0;


            if (checkImages.length === 0) {










                return (
                    <Button fullWidth
                        size="large" variant="contained" color="primary"
                        style={{
                            textTransform: 'none', backgroundColor: images.length === 0 ? 'rgba(0,0,0,0.4)' : '#3b5998',
                            color: 'white', fontSize: 16, padding: 6, paddingLeft: 20, paddingRight: 20
                        }}


                        onClick={async () => {
                            this.setState({ addingImages: true })


                            //get docs from this user



                            const newImagesLength = currentImages + item.images.length;
                            if (newImagesLength > imagesLimit) {
                                this.setState({ addingImages: false })
                                this.props.openSnack('error', 'You have reached your image limit. Please upgrade your subscription to upload more images.')
                            }
                            else {
                                this.props.openSnack('success', 'Adding Images')
                                const db = getFirestore();
                                if ([checkPrompt, checkImageLength].includes(false)) {
                                    alert('Please add a prompt and at least one image');
                                    this.setState({ addingImages: false })
                                } else {
                                    const images = item.images;
                                    images.map(async (image, index) => {

                                        delete image.preview;
                                        image.prompt = item.prompt;
                                        image.description = item.description;
                                        image.tags = item.tags;
                                        image.maker = item.maker;


                                        await setDoc(doc(db, "items", image.key), image).then(() => {
                                            //if last image return to my-gallery
                                            if (index === images.length - 1) {
                                                navigate(`/profile/${uid}`);
                                                this.setState({ addingImages: false })
                                            }
                                        })
                                    })
                                }
                            }





                        }
                        }

                    > Add to Gallery</Button >
                )
            }
            else {
                return <CircularProgress />;
            }

        }






        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle" justify='center'>
                        <Col xs={16} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'center' }}>
                            <Typography variant="h4">What are you making?</Typography>
                        </Col>
                        <Col xs={16} style={{ paddingTop: 20, paddingBottom: 15, textAlign: 'center' }}>
                            <Typography variant="h6">Upload images.  These will be added to your gallery.</Typography>
                        </Col>
                        <Col xs={16} style={{ paddingBottom: 15, textAlign: 'center' }}>
                            {activeSubscription.priceId !== 'price_1Lz3SNKNkNgX2BextzRvIg6F' && (<Typography variant="body1" style={{ color: 'rgba(0,0,0,0.3)' }}>You have uploaded {currentImages} images.
                                {imagesLimit - currentImages < 0 ? " No" : imagesLimit - currentImages} Images Left</Typography>)}



                        </Col>

                        <Col span={16} style={{ paddingTop: 20 }} >
                            <Row>
                                <Col span={24}>
                                    <Typography variant="h6">Pompt<span style={{ color: 'red' }}>*</span></Typography>
                                </Col>
                                <Col span={24} >
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Prompt"
                                        size="large"
                                        onChange={(e) => {
                                            this.updateItem('prompt', e.target.value)
                                        }}
                                        value={item.prompt}
                                        fullWidth
                                        style={{
                                            width: '100%', fontSize: 18, padding: 12, border: '1px solid #ccc', borderRadius: 5,
                                            color: 'black', backgroundColor: 'white', fontFamily: 'inter'
                                        }}
                                    />

                                </Col>
                            </Row>
                        </Col>
                        <Col span={16} style={{ paddingTop: 20 }} >
                            <Row>
                                <Col span={24}>
                                    <Typography variant="h6">Maker</Typography>
                                </Col>
                                <Col span={24}>

                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={makers.sort((a, b) => -b.value.localeCompare(a.value))}
                                        getOptionLabel={(option) => option.label}
                                        fullWidth
                                        ChipProps={{ variant: 'outlined', color: 'primary' }}
                                        renderInput={(params) => <TextField {...params} label="Makers" />}
                                        value={item.maker || null}
                                        onChange={(e, props) => {


                                            this.updateItem('maker', props)
                                        }}

                                    />


                                </Col>
                            </Row>
                        </Col>
                        <Col span={16} style={{ paddingTop: 20 }} >
                            <Row>
                                <Col span={24}>
                                    <Typography variant="h6">Tags</Typography>
                                </Col>
                                <Col span={24}>
                                    <Autocomplete
                                        disablePortal
                                        multiple
                                        disableCloseOnSelect={true}
                                        id="combo-box-demo"
                                        options={tags.sort((a, b) => -b.value.localeCompare(a.value))}
                                        getOptionLabel={(option) => option.label}
                                        fullWidth
                                        ChipProps={{ variant: 'outlined', color: 'primary' }}
                                        renderInput={(params) => <TextField {...params} label="Tags" />}
                                        value={item.tags}
                                        onChange={(e, props) => {

                                            const tagLabels = props.map(item => item.label)
                                            const tagValues = props.map(item => item.value)
                                            this.updateItem('tags', props)
                                            this.updateItem('tagLabels', tagLabels)
                                            this.updateItem('tagValues', tagValues)
                                        }}

                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={16} style={{ paddingTop: 20 }} >
                            <Row>
                                <Col span={24}>
                                    <Typography variant="h6">Description (optional)</Typography>
                                </Col>
                                <Col span={24}>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Description"
                                        size="large"
                                        value={item.description}
                                        onChange={(e) => {
                                            this.updateItem('description', e.target.value)
                                        }}
                                        fullWidth
                                        style={{
                                            width: '100%', fontSize: 18, padding: 12, border: '1px solid #ccc', borderRadius: 5,
                                            color: 'black', backgroundColor: 'white', fontFamily: 'inter'
                                        }}
                                    />

                                </Col>
                            </Row>
                        </Col>
                        <Col span={16} style={{ paddingTop: 20 }} >
                            <Row>
                                <Col span={24}>
                                    <Typography variant="h6">Make all public</Typography>
                                </Col>
                                <Col span={24}>
                                    <Switch
                                        checked={item.public}
                                        onChange={(e) => {
                                            this.updateItem('public', e.target.checked)

                                            const images = this.state.item.images
                                            images.forEach(image => {
                                                image.public = e.target.checked
                                            }
                                            )
                                            this.updateItem('images', images)

                                        }}
                                        color="primary"
                                        name="checkedB"
                                    />


                                </Col>
                            </Row>
                        </Col>
                        <Col span={16} style={{ paddingTop: 20, }}>
                            <Row >
                                <Col span={24}>
                                    <Typography variant="h6">Images</Typography>
                                </Col>
                                < Col {...fullMobile(24, 24)} style={{ borderRadius: 12, paddingBottom: 20, }} className="card-container"
                                    onDragOver={() => this.handleDragEnter()}
                                    onDragEnter={() => this.handleDragEnter()}
                                    onDragLeave={() => this.handleDragExit()}
                                    onMouseLeave={() => this.handleDragExit()}
                                    onMouseEnter={() => this.handleDragEnter()}
                                    onDrop={() => this.handleDragExit()} >
                                    <Dropzone multiple={true} maxSize={10000000}

                                        accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}

                                        onDrop={(acceptedFiles) => {

                                            acceptedFiles.map((f, i) => {



                                                const storage = getStorage();
                                                const key = uuid()

                                                const photo = {
                                                    fileCreateDate: moment().format('x'),
                                                    date: Number(moment().format('x')),
                                                    downloadURL: '',
                                                    url: '',
                                                    name: f.name,
                                                    nickname: '',
                                                    preview: '',
                                                    key: key,
                                                    metadata: '',
                                                    likes: 0,
                                                    views: 0,
                                                    public: false,
                                                    tags: [],
                                                    tagLabels: [],
                                                    tagValues: [],
                                                    customTags: [],
                                                    description: '',
                                                    maker: '',
                                                    prompt: '',
                                                    comments: [],
                                                    collections: [],
                                                    creator: uid,
                                                    createDate: moment().format('x'),
                                                    thumbnailPath: `images/${key}_200x200`,
                                                    thumbnailPath1: `images/${key}_500x500`,
                                                    thumbnailURL: '',
                                                    thumbnailUR1: '',
                                                    finalized: 'uploading',
                                                    userName: user.displayName,
                                                    reports: [],
                                                    reported: false


                                                };

                                                item.images.push(photo);
                                                this.setState({ item })

                                                const storageRef = ref(storage, `images/${key}`);



                                                //get thumbnail of file 






                                                const uploadTask = uploadBytesResumable(storageRef, f);
                                                uploadTask.on('state_changed',
                                                    (snapshot) => {
                                                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                                                        progressBars[key] = progress;
                                                        this.setState({ progressBars })

                                                        switch (snapshot.state) {
                                                            case 'paused':
                                                                console.log('Upload is paused');
                                                                break;
                                                            case 'running':
                                                                console.log('Upload is running');
                                                                break;
                                                        }
                                                    },
                                                    (error) => {
                                                        console.log(error)
                                                    },
                                                    () => {
                                                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                                            const reader = new FileReader();
                                                            reader.readAsDataURL(f);
                                                            reader.onloadend = () => {
                                                                const base64data = reader.result;

                                                                photo.downloadURL = downloadURL;
                                                                photo.url = downloadURL;
                                                                photo.public = item.public;
                                                                photo.preview = base64data;
                                                                photo.finalized = 'uploaded';

                                                                const index = item.images.findIndex(i => i.key === key)
                                                                item.images[index] = photo;

                                                                setTimeout(() => {
                                                                    this.getThumbnailUrl(photo)
                                                                }, 2500);

                                                                this.setState({ item })

                                                                delete progressBars[key];
                                                                this.setState({ progressBars })

                                                            }



                                                        })
                                                    }
                                                )














                                            })
                                        }}

                                        onError={(err) => {
                                            console.log(err)
                                        }}

                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section style={{ height: '100%' }}>
                                                <div {...getRootProps()} style={{
                                                    borderRadius: 12, alignItems: 'center', paddingBottom: 20,
                                                    justifyContent: 'center', display: 'flex', cursor: 'pointer', height: '100%',
                                                    backgroundColor: '#fff', padding: '30px', fontWeight: 600, fontSize: '18px',
                                                    boxShadow: isDragActive ? '0 8px 16px 0 rgba(0, 0, 0, 0.2)' : 'inset 0 0 0 1px #d9d9d9',
                                                    transition: 'box-shadow 1s ease-in-out'


                                                }}>


                                                    <input {...getInputProps()} />
                                                    <Row gutter={[12, 12]} style={{ paddingTop: 20 }}>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <FontAwesomeIcon fontSize={40} icon={faCloudArrowUp} />
                                                        </Col>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Typography variant="h6">Drag and drop files here, or click to BROWSE</Typography>
                                                        </Col>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Typography variant="body1">Max 10MB each (no videos YET).</Typography>
                                                        </Col>
                                                    </Row>


                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Col>
                                <Col span={24} >
                                    <Row gutter={[12, 12]}>
                                        {
                                            Object.values(progressBars).map((p, i) => {
                                                return (
                                                    <Col {...fullMobile(8, 24)} style={{ padding: 10 }} >
                                                        <Row>
                                                            <Col span={24}
                                                                style={{
                                                                    borderRadius: 12, borderRadius: 12, alignItems: 'center',
                                                                    justifyContent: 'center', display: 'flex', cursor: 'pointer', height: '90%', width: '90%',
                                                                    backgroundColor: '#fff', color: '#f47e42', padding: '30px', fontWeight: 600, fontSize: '18px',
                                                                    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
                                                                }} className="card-container"




                                                            >
                                                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                                                    <CircularProgress variant="determinate" value={p} />
                                                                    <Box
                                                                        sx={{
                                                                            top: 0,
                                                                            left: 0,
                                                                            bottom: 0,
                                                                            right: 0,
                                                                            position: 'absolute',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography variant="caption" component="div" color="text.secondary">
                                                                            {`${Math.round(p)}%`}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>

                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </Col>

                                <Col span={24} >
                                    <Row gutter={[12, 12]}>
                                        {
                                            item.images.sort((a, b) => a.name.localeCompare(b.name)).filter(image => ['uploaded', 'finalized'].includes(image.finalized)).map((item, index) => {



                                                return (
                                                    <Col {...fullMobile(8, 24)} style={{ padding: 10 }} >
                                                        <Row>
                                                            <Col span={24}
                                                                style={{
                                                                    borderRadius: 12, borderRadius: 12, alignItems: 'center',
                                                                    justifyContent: 'center', display: 'flex', cursor: 'pointer', height: '90%', width: '90%',
                                                                    backgroundColor: '#fff', padding: '30px', fontWeight: 600, fontSize: '18px',
                                                                    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
                                                                }} className="card-container"




                                                            >
                                                                <Row gutter={[12, 12]}>

                                                                    <Col span={24} style={{ textAlign: 'right' }}>
                                                                        <Row gutter={[4, 4]} justify="end" align="middle">
                                                                            <Col>
                                                                                <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.3)' }}><Favorite size="small" style={{ color: 'rgba(0,0,0,0.3)' }} /> {item.likes}</Typography>
                                                                            </Col>
                                                                            <Col style={{ paddingLeft: 15 }}>
                                                                                <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.3)' }}><RemoveRedEye size="small" style={{ color: 'rgba(0,0,0,0.3)' }} />{item.views}</Typography>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>


                                                                    <Col span={24} style={{ textAlign: 'center', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                lightIndex: index,
                                                                                lightOpen: true
                                                                            })
                                                                        }} >


                                                                        <img src={item.preview || item.url} style={{ width: '100%', height: '100%', maxHeight: 300, objectFit: 'contain', }} />


                                                                    </Col>
                                                                    <Col span={24} style={{ paddingTop: 10 }} >
                                                                        <Row align="middle">
                                                                            <Col span={12}>
                                                                                <Typography variant="body2">Nickname</Typography>
                                                                            </Col>
                                                                            <Col span={6}>
                                                                                <Typography variant="body2">Public</Typography>
                                                                            </Col>
                                                                            <Col span={6}>
                                                                                <Switch checked={item.public} onChange={(e) => {

                                                                                    this.updateImage(index, 'public', e.target.checked)
                                                                                }} />

                                                                            </Col>
                                                                            <Col span={24}>
                                                                                <OutlinedInput
                                                                                    fullWidth
                                                                                    size='small'
                                                                                    id="outlined-adornment-weight"
                                                                                    value={item.nickname}
                                                                                    onChange={(e) => {
                                                                                        this.updateImage(index, 'nickname', e.target.value)
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>

                                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                                        <IconButton onClick={() => {

                                                                            this.deletePhoto(item.key)

                                                                        }} style={{ position: 'relative', zIndex: 1 }} size="small" color="inherit" aria-label="upload picture" component="span">
                                                                            <DeleteForeverIcon />
                                                                        </IconButton>
                                                                    </Col>

                                                                    <Col span={12} style={{ textAlign: 'left' }}>
                                                                        <IconButton onClick={async () => {


                                                                            this.setState({ downloading: true })

                                                                            const blob = await (await fetch(item?.url)).blob();
                                                                            FileSaver.saveAs(blob, `${item?.prompt || "image"}.png`);


                                                                        }} style={{ position: 'relative', zIndex: 1 }} size="small" color="inherit" aria-label="upload picture" component="span">
                                                                            {downloading ? <CircularProgress /> : <DownloadingOutlined />}
                                                                        </IconButton>
                                                                    </Col>



                                                                </Row>
                                                            </Col >
                                                        </Row>
                                                    </Col>

                                                )






                                            })
                                        }
                                    </Row>

                                    <Lightbox
                                        open={lightOpen}
                                        close={() => this.setState({ lightOpen: false })}
                                        index={lightIndex}
                                        slides={item.images.map((item, index) => {
                                            return {
                                                src: item.preview || item.url,
                                                title: item.nickname,
                                                description: this.state.item.prompt,
                                                alt: item.name,
                                                key: item.key
                                            }
                                        })}
                                        plugins={[Captions]}


                                    />
                                </Col>

                                <Col span={24} style={{ textAlign: 'center', paddingTop: 40 }}>
                                    {addingImages === false && (<AddImagesButton />)}
                                    <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.3)' }}>It may take a minute or two to appear in the gallery.</Typography>
                                    {addingImages === true && (<CircularProgress />)}
                                </Col>


                            </Row>
                        </Col>

                    </Row>
                </Col>

            </Row >
        );
    }
} 