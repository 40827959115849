import { Button, CardActions, CardContent, CircularProgress, Grid, IconButton, Paper, Switch, TextareaAutosize, Typography } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';


import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';


import { DeleteForever, FavoriteBorder, KeyboardArrowLeft, KeyboardArrowRight, RemoveRedEye } from '@mui/icons-material';
import { Configure, Highlight, InstantSearch, SearchBox, useInfiniteHits } from 'react-instantsearch-hooks-web';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSwipeable } from 'react-swipeable';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, getDoc, deleteDoc, updateDoc, setDoc, arrayUnion, query, collectionGroup, getDocs, collection, where, onSnapshot } from 'firebase/firestore';
import { StickyContainer, Sticky } from 'react-sticky';
import { getAuth } from 'firebase/auth';

var moment = require('moment-timezone');






const searchClient = algoliasearch('6VAIIOAHBD', '7c4faabcd334a04d445b6d99261ea04e');

function CustomInfiniteHits(props) {
    const { hits,
        currentPageHits,
        results,
        isFirstPage,
        isLastPage,
        showPrevious,
        showMore,
        sendEvent, } = useInfiniteHits(props);
   
    const [currentCard, setCurrentCard] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [likedImages, setLikedImages] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [profileid, setProfileid] = useState(null);
    const [collectionImages, setCollectionImages] = useState(props.item?.images || []);
    const [collageLayout, setCollageLayout] = useState([1, 1]);
    const [cardIndex, setCardIndex] = useState(0);


    const [hitList, setHits] = useState(hits)

    useEffect(() => {
        setLikedImages(props.userInfo?.likedImages || []);
        setHits(hits)

    }, [props, hits, props.isCollageOpen]);




    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);


    };




    function updateImages(type, image) {
        if (type === 'add') {
            setCollectionImages([...collectionImages, image])
            props.updateCollectionImages([...collectionImages, image])
        }
        else {
            setCollectionImages(collectionImages.filter((item) => item !== image))
            props.updateCollectionImages(collectionImages.filter((item) => item !== image))
        }
    }












    return (
        <Grid container spacing={2} >

            {hitList.map((hit, index) => {
                const hitKey = `${hit.key}`;
                return (

                    <Grid item xs={6} sm={4} md={4} lg={3}>




                        <Paper elevation={4} sx={{
                            border: collectionImages.includes(hit.objectID) ? '4px solid #3f51b5' : 'none',
                            ":hover": {
                                boxShadow: 10,
                                //move the card up slowly

                                transform: "translateY(-5px)",
                                transition: "all 0.3s ease-in-out",

                            },
                        }}
                        >



                            <CardContent style={{ cursor: 'pointer', padding: props.screenWidth !== 'xs' ? 10 : 5, paddingBottom: 0, }} sx={{ paddingBottom: 0 }}
                                onClick={async () => {
                                    setCardIndex(index)
                                    if (collectionImages.includes(hit.objectID)) {
                                        updateImages('remove', hit.objectID)
                                    }
                                    else {
                                        updateImages('add', hit.objectID)
                                    }


                                }} >



                                <img src={hit.url} style={{ maxWidth: '100%', }} alt='alt' />
                                <Typography variant="body2" color="rgba(0,0,0,0.8)" fontSize={props.screenWidth !== 'xs' ? 13 : 10} fontWeight={500} style={{ paddingTop: 10 }}>
                                    <Highlight attribute="prompt" hit={hit} />
                                </Typography>

                            </CardContent>
                            <CardActions style={{ paddingTop: 0, paddingBottom: 0 }} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <FavoriteBorder fontSize='small' />
                                <Typography variant="body2" color="rgba(0,0,0,0.8)" fontSize={props.screenWidth !== 'xs' ? 12 : 10} fontWeight={500}>
                                    {hit.likes}
                                </Typography>
                                <IconButton aria-label="share" style={{ marginLeft: 20 }}>
                                    <RemoveRedEye fontSize='small' />
                                </IconButton>

                                <Typography variant="body2" fontSize={props.screenWidth !== 'xs' ? 12 : 10} color="rgba(0,0,0,0.8)" fontWeight={500} style={{ paddingRight: 20 }}>
                                    {hit.views}
                                </Typography>




                            </CardActions>






                        </Paper>



                    </Grid>

                )
            })}







            {!isLastPage && (<Grid item xs={12} >

                <Button style={{ fontFamily: 'Inter', textTransform: 'none', width: '100%', height: '100%', borderRadius: 0, backgroundColor: '#f5f5f5', color: '#000000', fontWeight: 700 }}
                    onClick={() => {
                        showMore()
                    }}>Show More</Button>

            </Grid>)}
            {isLastPage && (<Grid item xs={12} style={{ textAlign: 'center' }} >

                <Typography style={{ fontFamily: 'Inter', textTransform: 'none', width: '100%', height: '100%', borderRadius: 0, backgroundColor: '#f5f5f5', color: '#000000', fontWeight: 700, textAlign: 'center' }}>
                    End of the line sorry! No more images.
                </Typography>

            </Grid>)}


        </Grid >

    )


}



function Search(props) {
    const { id } = useParams();
    const filters = props.type === 'main' ? "public:true" : `creator:${id}`;

    console.log(props)

    return (
        <StickyContainer>
        {/* Other elements can be in between `StickyContainer` and `Sticky`,
    but certain styles can break the positioning logic used. */}
        <InstantSearch
            indexName="itemSearch"
            searchClient={searchClient}
            stalledSearchDelay={200}
        >
            <Row style={{}}>
                {/* <Col xs={0} sm={4} style={{ paddingRight: 30 }}>
                    <Typography variant="h6" style={{}}>Filters</Typography>
                    <Divider />
                    <RefinementList attribute="maker" style={{ fontFamily: 'Inter', paddingTop: 10 }} />
                </Col> */}
                <Col xs={24} style={{}}>


                    <Sticky topOffset={80}>
                        {({
                            style,

                            // the following are also available but unused in this example
                            isSticky,
                            wasSticky,
                            distanceFromTop,
                            distanceFromBottom,
                            calculatedHeight 
                        }) => (
                            <header style={{ ...style, paddingTop: 10, zIndex: 100 }}>
                                {props.type === 'my' && props.imagesLimit > props.currentImages ? <SearchBox
                                    searchAsYouType={true}
                                    autoFocus={false}
                                /> :
                                    <Typography variant="body1" style={{ fontFamily: 'Inter', paddingTop: 10, textAlign: 'center' }}>
                                        You have reached your image limit. Upgrade to search through your images.</Typography>}
                            </header>
                        )}
                    </Sticky>


                    <Configure

                        hitsPerPage={48}

                        analytics={false}
                        filters={filters}

                    />
                    <Col xs={24} style={{}}>

                        <Row style={{ paddingTop: 15 }}>
                            <CustomInfiniteHits {...props} openSnack={props.openSnack} updateCollagePhotos={props.updateCollagePhotos}
                                screenWidth={props.screenWidth} updateCollectionImages={props.updateCollectionImages} />
                        </Row>
                    </Col>

                </Col>
            </Row>



        </InstantSearch>
        </StickyContainer>
    );
}


export default class SearchAll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            uid: this.props.uid,
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            card: null,
            collagePhotos: [],
            isCollageOpen: false,
            item: this.props.item,
            imagesLimit: 100,
            currentImages: 0
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item })
        }

    }

    async componentDidMount() {

        const auth = getAuth();

        if (auth.currentUser !== null) {
            this.setState({ isLoggedIn: true })

            const auth = getAuth();
            const user = auth.currentUser;

            this.setState({ user })
            const db = getFirestore();
            const activeRef = getDocs(collection(db, "customers", this.state.uid, 'subscriptions'), where("status", "in", ["trialing", "active"]));
            const q = query(collection(db, "items"), where("creator", "==", this.state.uid));
            const querySnapshot = await getDocs(q);
            const images = []
            console.log(querySnapshot.size)

            this.setState({ currentImages: querySnapshot.size })

            activeRef.then((querySnapshot) => {
                querySnapshot.forEach(async (snap) => {

                  
                    const subscription = snap.data();
                    const priceId = subscription.items[0].price.id;
                    const priceData = (await getDoc(subscription.price)).data();
                    const item = { ...subscription, id: snap.id, metadata: priceData.metadata, priceId: priceId }
                    this.setState({ activeSubscription: item, priceId, })



                    const imagesLimit = priceId === 'price_1Lz3SNKNkNgX2BexPg4EgZC1' ? 350 :
                        priceId === 'price_1Lz3SNKNkNgX2BextzRvIg6F' ? 99999 : 100;
                    console.log(imagesLimit)
                    this.setState({ imagesLimit })

                });
            }
            ).catch((error) => {
                console.log("Error getting documents: ", error);
            }
            );

        }
        else {
            this.setState({ isLoggedIn: false })
        }

    }




    render() {

        const { uid, type, screenWidth, userInfo, collagePhotos, isCollageOpen, item, creating, currentImages, imagesLimit } = this.state;






        return (
            <Col span={24}>
                <Search uid={uid} type={type} setCurrentCard={this.setCurrentCard} screenWidth={screenWidth} userInfo={userInfo} item={item}
                    openSnack={this.props.openSnack} updateCollectionImages={this.props.updateCollectionImages} isCollageOpen={isCollageOpen} currentImages={currentImages} imagesLimit={imagesLimit}
                />
            </Col>

        );
    }
} 