import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider, CircularProgress } from '@mui/material';
import { getAuth, sendPasswordResetEmail, updateEmail, updateProfile } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, where, onSnapshot } from 'firebase/firestore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions'
import { useNavigate, useParams } from 'react-router-dom';
import ProfileTabs from './profileTabs';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import moment from 'moment';
import AboutPanel from './aboutPanel';
import Gallery from '../pages/gallery';
import SettingsPanel from './settings';
import CollectionsGallery from './collectionsGallery';

const stripePromise = loadStripe('pk_test_51Ls85PKNkNgX2BexkbwAvdJ8RE46JG4YGawZhaOcII2zLQ1GdBbNvkOf9anMnMgTJOUvNRGQjjfhMLV5vRTTx69c00da00c7oS');



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            user: {},
            uid: this.props.uid,
            products: [],
            activeSubscription: undefined,
            disaplayName: '',
            email: '',
            accessingPortal: false,
            value: 0,
            profileid: undefined
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const auth = getAuth();
        const user = auth.currentUser;


        if (user) {


            this.setState({ user, displayName: user.displayName, email: user.email })

        }

        //get products from firebase
        const db = getFirestore();
        const docRef = getDocs(collection(db, "products", 'prod_MiURaSSRks7gOw', 'prices'), where('active', ' == ', true));
        docRef.then((querySnapshot) => {
            const products = [];
            querySnapshot.forEach((doc) => {
                products.push({ ...doc.data(), priceId: doc.id });
            });
            this.setState({ products })

        }
        ).catch((error) => {
            console.log("Error getting documents: ", error);
        }
        );

        const userRef = getDoc(doc(db, "customers", this.state.uid));
        userRef.then((doc) => {
            if (doc.exists) {

            } else {
                // doc.data() will be undefined in this case

            }
        }
        ).catch((error) => {
            console.log("Error getting document:", error);
        }
        );



        const activeRef = getDocs(collection(db, "customers", this.state.uid, 'subscriptions'), where("status", "in", ["trialing", "active"]));

        const activeSubscriptions = [];

        activeRef.then((querySnapshot) => {
            querySnapshot.forEach(async (snap) => {

                console.log(snap.data())
                console.log(snap.id)

                const subscription = snap.data();
                const priceId = subscription.items[0].price.id;
                const priceData = (await getDoc(subscription.price)).data();

                const item = { ...subscription, id: snap.id, metadata: priceData.metadata, priceId: priceId }

                this.setState({ activeSubscription: item })



            });
        }
        ).catch((error) => {
            console.log("Error getting documents: ", error);
        }
        );


    }

    handleChange = (newValue) => {
        this.setState({ value: newValue })
    }


    findProfile = (profileid) => {
        const db = getFirestore();
        const userRef = doc(db, "customers", profileid);
        getDoc(userRef).then((doc) => {
            if (doc.exists()) {
                console.log(doc.data())
                console.log(profileid)
                this.setState({ userInfo: doc.data() })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    render() {

        const { user, products, activeSubscription, displayName, email, accessingPortal, value, userInfo, screenWidth, profileid } = this.state;


        const UpdateButton = () => {
            const auth = getAuth();
            const user = auth.currentUser;

            return (
                <Button style={{ textTransform: 'none', backgroundColor: '#3b5998', color: 'white', fontSize: 16, padding: 6, paddingLeft: 20, paddingRight: 20 }}
                    onClick={() => {
                        updateProfile(user, {
                            displayName: displayName,
                        }).then(() => {
                            console.log(user.uid)
                            console.log(displayName)
                            const proxyUrl = "https://mycorslake.herokuapp.com/";
                            axios.post(proxyUrl + 'https://us-central1-cloud-prompt-ba4db.cloudfunctions.net/profileUpdate/updateDisplayItems', {
                                creator: user.uid,
                                newDisplayName: displayName,


                            }).then(async (response) => {
                                // handle success

                                console.log(response)




                            }).catch((error) => {
                                console.log(error)
                            })
                        }).then(() => {
                            updateEmail(user, email).then(() => {



                            }).catch((error) => {
                                console.log(error)
                            });
                        }).catch((error) => {
                            console.log(error)
                        });
                    }}>Update</Button>
            )


        }

        //create Send password reset button
        const SendPasswordResetButton = () => {
            const auth = getAuth();
            const user = auth.currentUser;
            return (
                <Button style={{ textTransform: 'none', backgroundColor: '#3b5998', color: 'white', fontSize: 16, padding: 6, paddingLeft: 20, paddingRight: 20 }} onClick={() => {
                    sendPasswordResetEmail(auth, user.email).then(() => {
                        console.log('Password reset email sent!')
                    }).catch((error) => {
                        console.log(error)
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(errorCode, errorMessage)
                    });
                }}>Send Password Reset</Button>
            )
        }

        const LogoutButton = () => {
            const auth = getAuth();
            const user = auth.currentUser;
            let navigate = useNavigate();
            return (
                <Button variant="contained" color="primary" onClick={() => {
                    const auth = getAuth();
                    auth.signOut().then(() => {
                        navigate('/')
                    }).then(() => {
                        this.props.logout()
                    }
                    ).catch((error) => {
                        // An error happened.
                    }
                    );

                }
                } style={{ textTransform: 'none', backgroundColor: '#3b5998', color: 'white', fontSize: 16, padding: 6, paddingLeft: 20, paddingRight: 20 }}>
                    Logout
                </Button>
            )


        }


        const UserCheck = () => {
            let { id } = useParams();
            if (id !== profileid) {
                this.setState({ profileid: id })
                this.findProfile(id)
            }
            return null;
        }

        const ProfileTab = (props) => {
            let { id } = useParams();

            const auth = getAuth();
            const user = auth.currentUser;
            if (id === user.uid) {
                return (
                    <Tab label={props.label} {...props} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />

                )
            }
            else {
                return null;
            }
        }
        const SettingsTab = (props) => {
            let { id } = useParams();
            const auth = getAuth();
            const user = auth.currentUser;
            if (id === user.uid) {
                return (
                    <Tab label={props.label} {...props} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />

                )
            }
            else {
                return null;
            }
        }

        const sidePadding = 30;

        const FavoritesTab = (props) => {
            let { id } = useParams();
            const auth = getAuth();
            const user = auth.currentUser;
            if (id === user.uid) {
                return (
                    <Tab label={props.label} {...props} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />

                )
            }
            else {
                return null;
            }
        }


        return (
            <Row style={{}}>
                <UserCheck />
                <Col span={24}>
                    <Row align="middle">
                        <Col sm={24} style={{ paddingLeft: 50, paddingTop: 30, paddingBottom: 15, height: '20vh', }}>
                            <Typography variant="h4">{profileid === user.uid ? "Your Profile" : `Welcome to ${userInfo?.displayName || 'someone' + "'s"}'s Profile`}</Typography>

                        </Col>
                        {profileid !== undefined && (<Col span={24} style={{ padding: screenWidth === 'xs' ? 0 : 25 }}>
                            <Box sx={{ width: '100%', fontFamily: 'Inter' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} variant="scrollable"
                                        scrollButtons="auto" onChange={(e, newValue) => {
                                            this.handleChange(newValue)
                                        }} aria-label="basic tabs example">
                                        <Tab label="Images" {...a11yProps(0)} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />
                                        <Tab label="Collections" {...a11yProps(1)} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />
                                        <Tab label="About" {...a11yProps(2)} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />
                                        <FavoritesTab label="Favorites" {...a11yProps(3)} />
                                        <ProfileTab label="Profile" {...a11yProps(4)} />
                                        <SettingsTab label="Plan" {...a11yProps(5)} />

                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>


                                    <Gallery uid={user.uid} type={'my'} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} index="itemSearch" isAuthedUser={profileid === user.uid}
                                        tools={['collage',]}

                                    />

                                </TabPanel>
                                <TabPanel value={value} index={1}>




                                    <CollectionsGallery uid={user.uid} type={'my'} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} index="collectionsSearch" isAuthedUser={profileid === user.uid}
                                        tools={['collage',]} />



                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Row>


                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 15, paddingTop: 30, }}>
                                            <Typography variant="h3">About</Typography>

                                        </Col>
                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 15, paddingTop: 30, }}>
                                            <AboutPanel user={user} userInfo={userInfo} loggedin={true} openSnack={this.props.openSnack} />
                                        </Col>
                                    </Row>

                                </TabPanel>
                                <TabPanel value={value} index={3}>


                                    <Gallery uid={user.uid} type={'favorites'} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} index="itemSearch" isAuthedUser={profileid === user.uid}
                                        tools={['collage',]}

                                    />

                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <Row>


                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 30, }}>
                                            <Typography variant="h3">Your Profile</Typography>

                                        </Col>
                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, }}>
                                            <Row>
                                                <Col span={24}>
                                                    <Typography variant="h6">Username</Typography>
                                                </Col>
                                                <Col span={24}>
                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        value={displayName}
                                                        onChange={(e) => {
                                                            this.setState({ displayName: e.target.value })
                                                        }}

                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 20 }} >
                                            <Row>
                                                <Col span={24}>
                                                    <Typography variant="h6">Email</Typography>
                                                </Col>
                                                <Col span={24}>
                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        value={email}
                                                        onChange={(e) => {
                                                            this.setState({ email: e.target.value })
                                                        }}


                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 30, }}>
                                            <UpdateButton />
                                        </Col>
                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 30, }}>
                                            <SendPasswordResetButton />
                                        </Col>
                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 30, }}>
                                            <LogoutButton />
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel value={value} index={5}>
                                    <Row>
                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 30, }}>
                                            <SettingsPanel screenWidth={screenWidth} activeSubscription={activeSubscription} products={products} user={user} openSnack={this.props.openSnack} />
                                        </Col>

                                    </Row>
                                </TabPanel>

                            </Box>
                        </Col>)}
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>

                        </Col>




                    </Row>
                </Col >
            </Row >
        );
    }
} 