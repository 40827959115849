import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail, updateEmail, updateProfile } from "firebase/auth";
import { Button, Divider, OutlinedInput } from '@mui/material';
import { Col, Row, } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';




export default function RoadMap(props) {

    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
    );

        window.scrollTo(0, 0);



    useEffect(() => {

 

        if (props.pageInfo !== null) {

            setEditorState(EditorState.createWithContent(props?.pageInfo));

        }



    }, [props.pageInfo]);



    const sidePadding = 30;





    return (
        <Row gutter={[8, 8]} style={{ padding: props.screenWidth === 'xs' ? 30: 100 }}>

            {props.type === 'greg' && (<Col span={24} style={{ border: '1px solid #000000', borderRadius: '5px', padding: '10px' }}>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorState}
                />
            </Col>)}
            {props.type === 'greg' && (<Col span={24} style={{ textAlign: 'right' }}>
                <Button variant="contained" color="primary" display="inline" style={{ textTransform: 'none', backgroundColor: '#3b5998', color: 'white', fontSize: 16, padding: 6, paddingLeft: 20, paddingRight: 20 }}

                    onClick={async () => {
                        const db = getFirestore();
                        const roadmapInfo = { pageInfo: convertToRaw(editorState.getCurrentContent()) };
                      
                        const docRef = doc(db, "roadmap", "1",);

                        // Set the "capital" field of the city 'DC'
                        await setDoc(docRef, roadmapInfo);




                    }}>
                    Update Road Map
                </Button>
            </Col>)}
            <Col span={24}>
                <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }} ></div>

            </Col>
        </Row>
    );


}
