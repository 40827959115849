import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider } from '@mui/material';

export default class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        const { account } = this.state;

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row align="middle">
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">Privacy Policy</Typography>
                        </Col>
                        <Col xs={6} sm={4} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>

                        </Col>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col span={23} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 30 }}>
                            <Typography variant="body1">Promptsss Privacy Policy

                                This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.promptsss.com (the “Site”).

                                </Typography><Typography variant="body1">PERSONAL INFORMATION WE COLLECT

                                When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”

                                </Typography><Typography variant="body1">We collect Device Information using the following technologies:

                                - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
                                - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                                - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.

                                Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers processed by Stripe), email address, and phone number.  We refer to this information as “Order Information.”



                                When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.

                                </Typography><Typography variant="body1">HOW DO WE USE YOUR PERSONAL INFORMATION?

                                WE DO NOT SELL ANY PERSONAL INFORMATION!!!

                                DO NOT TRACK
                                Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.



                                </Typography><Typography variant="body1">YOUR RIGHTS
                                If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                                Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.


                                </Typography><Typography variant="body1">DATA RETENTION
                                When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.



                                </Typography><Typography variant="body1">CHANGES
                                We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                                CONTACT US
                                For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at greg@promptsss.com or by mail using the details provided below:

                                26232 Morning Glen, Lake Forest, CA, 92630, United States</Typography>
                        </Col>
                    </Row>
                </Col>

            </Row>
        );
    }
}

