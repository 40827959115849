import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Col, Drawer, Modal, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider, Grid, IconButton, CardActions, CardContent, CircularProgress, Switch, Fab, Slider, TextareaAutosize, Autocomplete, TextField } from '@mui/material';


import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';


import { InstantSearch, RefinementList, SortBy, SearchBox, Hits, Highlight, Pagination, useHits, Configure, InfiniteHits, useInfiniteHits, Menu, } from 'react-instantsearch-hooks-web';
import { ArrowDownward, DeleteForever, DownloadingOutlined, Favorite, FavoriteBorder, FavoriteOutlined, Flag, FlagOutlined, KeyboardArrowLeft, KeyboardArrowRight, RemoveRedEye } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import GalleryCard from './galleryComponents/galleryCard';
import { getFirestore, arrayUnion, arrayRemove, updateDoc, doc, deleteDoc, setDoc, getDoc, getDocs, collection, where, query } from 'firebase/firestore';
import { async } from '@firebase/util';
import uuid from 'react-uuid';
import axios from 'axios';
import FileSaver, { saveAs } from 'file-saver';
import { useNavigate, useParams } from 'react-router-dom';

import { useSwipeable } from 'react-swipeable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { ReactPhotoCollage } from "react-photo-collage";
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import GallerySet from './galleryComponents/gallerySet';
import SortableGalleryPhotos from './galleryComponents/sortableGallery';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import download from 'downloadjs';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ScrollToTop from "react-scroll-to-top";
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { StickyContainer, Sticky } from 'react-sticky';
import domtoimage from 'dom-to-image';
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
var moment = require('moment-timezone');






const searchClient = algoliasearch('6VAIIOAHBD', '7c4faabcd334a04d445b6d99261ea04e');

function CustomInfiniteHits(props) {
    const { hits,
        currentPageHits,
        results,
        isFirstPage,
        isLastPage,
        showPrevious,
        showMore,
        sendEvent, } = useInfiniteHits(props);

    const [currentCard, setCurrentCard] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [collageOpen, setCollageOpen] = useState(false);
    const [likedImages, setLikedImages] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [profileid, setProfileid] = useState(null);
    const [collagePhotos, setCollagePhotos] = useState([]);
    const [collageLayout, setCollageLayout] = useState([1, 1]);
    const [cardIndex, setCardIndex] = useState(0);
    const [collectionImages, setCollectionImages] = useState(props.collectionImages || []);
    const [opacity, setOpacity] = useState(0);
    const [preferencesModal, setPreferencesModal] = useState(false);

    const [lockScreen, setLockScreen] = useState(false);


    const [hitList, setHits] = useState(hits)

    useEffect(() => {
        setLikedImages(props.userInfo?.likedImages || []);
        setHits(hits)
        setCollageOpen(props.isCollageOpen)

        if (props.isCollageOpen !== collageOpen) {
            document.body.style.overflow = 'hidden';
        }

    }, [hits, props.isCollageOpen,]);




    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setCollageOpen(false);
        setLockScreen(false);


    };

    const setCurrentCardFunc = (card) => {
        setCurrentCard(card);

        showModal();

    }

    //function to get new card 
    function getNewCard(type) {
        //find index of current card
        const index = hitList.findIndex(x => x.objectID === currentCard.objectID)
        //get new index based on type, but cannot be lower than 0, or bigger than hits.length
        const newIndex = (index + (type === 'prev' ? -1 : 1)) <= 0 ? 0 : (index + (type === 'prev' ? -1 : 1)) >= hitList.length ? hitList.length - 1 : (index + (type === 'prev' ? -1 : 1))
        //set new card

        setCurrentCard({ ...hitList[newIndex], index: newIndex })
        setCardIndex(newIndex)
        if (!props.tools.includes('collectionSelect')) {
            if (!props.isAuthedUser) {
                updateViews({ ...hitList[newIndex], index: newIndex })
            }

        }


    }

    document.onkeydown = checkKey;

    function checkKey(e) {

        e = e || window.event;


        if (e.keyCode == '37') {
            getNewCard('prev')
            setEditMode(false)
        }
        if (e.keyCode == '39') {
            getNewCard('next')
            setEditMode(false)
        }

    }


    const UpdateCard = ({ card, updateHits }) => {
        const db = getFirestore();

        const currentCard = card;

        return (
            <IconButton aria-label="add to favorites"
                onClick={async () => {
                    //get current user

                    const user = props.userInfo;

                    const includes = props.userInfo.likedImages === undefined ? false : props.userInfo.likedImages.includes(currentCard?.objectID)

                    const docRef = doc(db, 'customers', user.uid)


                    if (includes === false) {
                        //add 1 like to current card and set it
                        setCurrentCard({ ...currentCard, likes: currentCard?.likes + 1 })
                        //update users liked items
                        setLikedImages([...likedImages, currentCard?.objectID])
                        updateHits({ ...currentCard, likes: currentCard?.likes + 1 })
                    }
                    else {
                        setCurrentCard({ ...currentCard, likes: currentCard?.likes - 1 })
                        const filteredLikedImages = likedImages.filter((f) => f !== currentCard?.objectID)

                        setLikedImages([...filteredLikedImages])
                        updateHits({ ...currentCard, likes: currentCard?.likes - 1 })

                        if (props.type === "favorites") {
                            const filteredHits = hitList.filter((f) => f.objectID !== currentCard?.objectID)
                            setHits([...filteredHits])
                        }
                    }


                    await updateDoc(docRef, {
                        likedImages: includes === false ? arrayUnion(currentCard?.objectID) : arrayRemove(currentCard?.objectID)
                    }).then(async () => {

                        //add 1 like to the item
                        const docRef = doc(db, 'items', currentCard?.objectID);
                        await updateDoc(docRef, {
                            likes: includes === false ? currentCard?.likes + 1 : currentCard?.likes - 1
                        })

                    }).then(() => {

                        var time = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");

                        const type = includes === false ? 'like' : 'unlike';
                        const key = uuid();
                        const itemkey = currentCard.objectID;
                        const item = {
                            time: `'${time}'`,
                            key: `'${key}'`,
                            type: `'${type}'`,
                            itemkey: `'${itemkey}'`,
                            userkey: `'${user.uid}'`


                        }

                        const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
                        const hourlyString = hourlyVals.join();


                        const proxyUrl = "https://mycorslake.herokuapp.com/";
                        axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                            raw: `INSERT INTO promptsss_likes VALUES ${hourlyString}; `
                        }).then(async (response) => {

                        }).catch((error) => {
                            console.log(error)
                        })
                    })
                }}


            >
                {likedImages.includes(currentCard?.objectID) ? <Favorite fontSize='small' style={{ fill: '#d36175' }} /> : <FavoriteBorder fontSize='small' />}
            </IconButton >
        )

    }


    const handleClick = useCallback(async () => {
        if (ref.current) {
            download(await toJpeg(ref.current), "test.jpg");

        }
    }, [ref?.current]);


    function updateHits(card) {

        const index = hitList.findIndex(x => x.objectID === card.objectID)
        const newHits = [...hitList]
        newHits[index] = card;
        setHits(newHits)
    }

    const UserButton = ({ hit }) => {
        let navigate = useNavigate();
        let { id } = useParams();


        return (
            <Button variant='contained' style={{
                padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 4, boxShadow: '2px 4px 3px 3px rgba(0,0,0,0,2)',
                color: 'white', textTransform: 'none',
                backgroundColor: '#7388cc',
            }}
                onClick={() => {
                    navigate(`/profile/${hit?.creator}`)
                }}>
                <Typography variant="body2" fontSize={10} fontWeight={700}
                    style={{
                        color: 'white'
                    }}>
                    {`${hit.userName?.substring(0, 10)}${hit.userName?.length > 10 ? '...' : ''}`}
                </Typography></Button>
        )

    }


    const DeleteButton = ({ hit }) => {

        return (
            <IconButton aria-label="delete" onClick={() => {
                const db = getFirestore();
                const docRef = doc(db, 'items', hit.objectID);
                deleteDoc(docRef).then(() => {

                }
                ).then(() => {
                    const newHits = hitList.filter((f) => f.objectID !== hit.objectID)
                    setHits(newHits)
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                }
                );
            }}>
                <DeleteForever />
            </IconButton>

        )

    }

    const ChangePublicSwitch = ({ hit }) => {

        return (
            <Switch checked={hit?.public} onChange={(e) => {

                const index = hitList.findIndex(x => x.objectID === hit.objectID)
                const newHits = [...hitList]
                newHits[index] = { ...newHits[index], public: e.target.checked };
                setHits(newHits)

                const db = getFirestore();
                const docRef = doc(db, 'items', hit.objectID);
                updateDoc(docRef, {
                    public: e.target.checked
                }).then(() => {

                }
                ).catch((error) => {
                    console.error("Error updating document: ", error);
                }
                );

            }} />
        )

    }
    const PublicText = ({ hit }) => {

        return (
            <Typography variant="body2" color="rgba(0,0,0,0.8)" fontWeight={500} style={{ paddingLeft: props.screenWidth === 'xs' ? 5 : 20 }}>
                Public
            </Typography>
        )

    }


    const handlers = useSwipeable({

        onSwipedLeft: (eventData) => {
            getNewCard('next')
            setEditMode(false)
        },
        onSwipedRight: (eventData) => {
            getNewCard('prev')
            setEditMode(false)
        }

    });





    function ImageCollage(props) {
        const [imageOpacity, setImageOpacity] = useState(0);

        const photos = collagePhotos.map((photo) => ({
            src: photo,
            width: 1,
            height: 1,
            sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],

        }
        ))

        return (
            <Row>

                <Col span={24}  >

                    <SortableGalleryPhotos photos={photos} showModal={lockScreen} screenWidth={props.screenWidth} opacity={imageOpacity} />



                </Col>

                <Col span={24} style={{ textAlign: 'center' }} id="node" ref={ref}>
                    <IconButton onClick={() => {

                        setDownloading(true)

                        if (props.screenWidth === 'xs') {


                            html2canvas(document.getElementById('my-node'), {
                                useCORS: true,
                            }).then(async canvas => {


                                const blob = await (await fetch(canvas.toDataURL())).blob();
                                FileSaver.saveAs(blob, `${"collage"}.png`);
                                setDownloading(false)
                            })


                        } else {

                            htmlToImage.toBlob(document.getElementById('my-node'))
                                .then((f) => {
                                    const storage = getStorage();
                                    const key = uuid()
                                    const storageRef = ref(storage, `collages/${key}`);

                                    delete f.creator;

                                    const uploadTask = uploadBytesResumable(storageRef, f);
                                    uploadTask.on('state_changed',
                                        (snapshot) => {
                                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;



                                            switch (snapshot.state) {
                                                case 'paused':
                                                    console.log('Upload is paused');
                                                    break;
                                                case 'running':
                                                    console.log('Upload is running');
                                                    break;
                                            }
                                        },
                                        (error) => {
                                            console.log(error)
                                        },
                                        () => {
                                            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                                const reader = new FileReader();
                                                reader.readAsDataURL(f);
                                                reader.onloadend = () => {
                                                    //open new tab with downloadurl


                                                    setDownloading(false)

                                                    const db = getFirestore();
                                                    const docRef = doc(db, 'collages', key);
                                                    setDoc(docRef, {
                                                        url: downloadURL,
                                                        createdAt: new Date(),
                                                    }).then(async () => {
                                                        const blob = await (await fetch(downloadURL)).blob();
                                                        FileSaver.saveAs(blob, `${"collage"}.png`);
                                                        props.openSnack('success', 'Collage saved!')
                                                    }).catch((error) => {
                                                        console.error("Error adding document: ", error);
                                                    });



                                                }



                                            })
                                        }
                                    )

                                })
                        }

                    }} style={{ position: 'relative', zIndex: 1 }} size="small" color="inherit" aria-label="upload picture" component="span">
                        {downloading ? <CircularProgress /> : <DownloadingOutlined />}
                    </IconButton>
                </Col>
            </Row >

        );
    }


    async function updateViews(hit, index) {
        setCurrentCardFunc({ ...hit, index, views: hit.views + 1 })
        const user = props.userInfo;
        const db = getFirestore();
        const currentCard = hit;

        updateHits({ ...currentCard, views: currentCard?.views + 1 })

        const docRef = doc(db, 'items', currentCard?.objectID);
        await updateDoc(docRef, {
            views: currentCard?.views + 1
        }).then(() => {

            var time = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");

            const type = 'view';
            const key = uuid();
            const itemkey = currentCard.objectID;
            const viewUser = user === undefined ? 'anonymous' : user.uid;
            const item = {
                time: `'${time}'`,
                key: `'${key}'`,
                type: `'${type}'`,
                itemkey: `'${itemkey}'`,
                userkey: `'${viewUser}'`


            }

            const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
            const hourlyString = hourlyVals.join();


            const proxyUrl = "https://mycorslake.herokuapp.com/";
            axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                raw: `INSERT INTO promptsss_views VALUES ${hourlyString}; `
            }).then(async (response) => {

            }).catch((error) => {
                console.log(error)
            })
        })
    }


    function updateImages(type, image) {

        if (type === 'add') {

            setCollectionImages([...collectionImages, image])
            props.updateCollectionImages([...collectionImages, image])
        }
        else {
            setCollectionImages(collectionImages.filter((item) => item !== image))
            props.updateCollectionImages(collectionImages.filter((item) => item !== image))
        }
    }


    const FlagImage = ({ hit, mobile }) => {

        return (
            < IconButton
                style={{
                    backgroundColor: '#f2f2f2',
                }
                }
                sx={{
                    width: mobile === true ? 30 : 60,
                    height: mobile === true ? 30 : 60,
                    borderRadius: 1,
                    backgroundColor: '#f2f2f2',
                }
                }
                onClick={async () => {

                    const user = props.userInfo;

                    if (user === undefined) {
                        props.openSnack('error', 'You must be logged in to flag an image')
                        return
                    }
                    else {
                        const db = getFirestore();
                        const reports = currentCard?.reports || [];
                        const includes = reports.includes(user?.uid);

                        const docRef = doc(db, 'items', currentCard?.objectID)

                        if (includes === false) {
                            //add 1 like to current card and set it
                            setCurrentCard({ ...currentCard, reports: [...reports, user?.uid], reported: true })
                            //update users liked items
                            updateHits({ ...currentCard, reports: [...reports, user?.uid], reported: true })
                            props.openSnack('warning', 'Image has been flagged')

                            await updateDoc(docRef, {
                                reports: includes === false ? arrayUnion(user?.uid) : arrayRemove(user?.uid),
                                reported: true
                            }).then(() => {

                                var time = moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss");

                                const type = 'report';
                                const key = uuid();
                                const itemkey = currentCard.objectID;
                                const item = {
                                    time: `'${time}'`,
                                    key: `'${key}'`,
                                    type: `'${type}'`,
                                    itemkey: `'${itemkey}'`,
                                    userkey: `'${user.uid}'`


                                }

                                const hourlyVals = [item].map((h) => `(${Object.values(h)})`);
                                const hourlyString = hourlyVals.join();


                                const proxyUrl = "https://mycorslake.herokuapp.com/";
                                axios.post(proxyUrl + 'https://us-central1-aquasource3.cloudfunctions.net/widgets/sqlRead', {
                                    raw: `INSERT INTO promptsss_likes VALUES ${hourlyString}; `
                                }).then(async (response) => {

                                }).catch((error) => {
                                    console.log(error)
                                })
                            })
                        }
                        else {
                            props.openSnack('error', 'You have already flagged this image')
                        }


                    }
                }}

            >
                <FlagOutlined fontSize='small' style={{ fontSize: props.screenWidth !== 'xs' ? 16 : 10 }} />
            </IconButton >
        )
    }



    const [editMode, setEditMode] = useState(false);

    function updateCurrentCard(val, boo) {
        setCurrentCardFunc({ ...currentCard, [val]: boo })
    }


    const tags = [
        { value: '3d', label: '3D' },
        { value: 'animation', label: 'Animation' },
        { value: 'art', label: 'Art' },
        { value: 'audio', label: 'Audio' },
        { value: 'code', label: 'Code' },
        { value: 'design', label: 'Design' },
        { value: 'game', label: 'Game' },
        { value: 'photo', label: 'Photo' },
        { value: 'video', label: 'Video' },
        { value: 'accessory', label: 'Accessory' },
        { value: 'apparel', label: 'Apparel' },
        { value: 'animals', label: 'Animals' },
        { value: 'architecture', label: 'Architecture' },
        { value: 'anime', label: 'Anime' },
        { value: 'buildings', label: 'Buildings' },
        { value: 'cartoon', label: 'Cartoon' },
        { value: 'clay', label: 'Clay' },
        { value: 'comics', label: 'Comics' },
        { value: 'nature', label: 'Nature' },
        { value: 'people', label: 'People' },
        { value: 'sculpture', label: 'Sculpture' },
        { value: 'space', label: 'Space' },
        { value: 'sports', label: 'Sports' },
        { value: 'vehicles', label: 'Vehicles' },
        { value: 'drawing', label: 'Drawing' },
        { value: 'painting', label: 'Painting' },
        { value: 'photography', label: 'Photography' },
        { value: 'printmaking', label: 'Printmaking' },
        { value: 'illustration', label: 'Illustration' },
        { value: 'fantasy', label: 'Fantasy' },
        { value: 'food', label: 'Food' },
        { value: 'furniture', label: 'Furniture' },
        { value: 'gadgets', label: 'Gadgets' },
        { value: 'gaming', label: 'Gaming' },
        { value: 'graphic', label: 'Graphic' },
        { value: 'handmade', label: 'Handmade' },
        { value: 'home', label: 'Home' },
        { value: 'jewelry', label: 'Jewelry' },
        { value: 'logos', label: 'Logos' },
        { value: 'mockups', label: 'Mockups' },
        { value: 'monogram', label: 'Monogram' },
        { value: 'holidays', label: 'Holidays' },
    ]
    const makers = [
        { value: 'DALL-E', label: 'DALL-E' },
        { value: 'Midjourney', label: 'Midjourney' },
        { value: 'Stable Diffusion', label: 'Stable Diffusion' },
        { value: 'Craiyon', label: 'Craiyon' },
        { value: 'Night Cafe', label: 'Night Cafe' },
        { value: 'Jasper Art', label: 'Jasper Art' },
        { value: 'Playground AI', label: 'Playground AI' },
        { value: 'Hugging Face', label: 'Hugging Face' },


    ]


    return (
        <Grid container spacing={1} >

            {hitList.map((hit, index) => {
                const hitKey = `${hit.key}`;
                return (

                    <Grid item xs={12 / props.gridSize} >




                        <Paper elevation={0} sx={{
                            border: props.tools.includes('collectionSelect') && collectionImages.includes(hit.objectID) ? '4px solid #3f51b5' : 'none',
                            padding: 0,
                            ":hover": {
                                boxShadow: 10,
                                //move the card up slowly

                                transform: "translateY(-5px)",
                                transition: "all 0.3s ease-in-out",

                            },
                        }}
                        >



                            <CardContent style={{ cursor: 'pointer', padding: props.screenWidth !== 'xs' ? 10 : 5, paddingBottom: 0, }} sx={{ paddingBottom: 0 }}
                                onClick={async () => {
                                    setCardIndex(index)
                                    if (!props.tools.includes('collectionSelect')) {

                                        if (!props.isAuthedUser) {
                                            updateViews(hit, index)
                                        }
                                        else {
                                            setCurrentCardFunc({ ...hit, index })
                                        }

                                    }
                                    else {
                                        if (collectionImages.includes(hit.objectID)) {
                                            updateImages('remove', hit.objectID)
                                        }
                                        else {
                                            updateImages('add', hit.objectID)
                                        }

                                    }



                                }} >


                                <div style={{ position: 'relative' }}>
                                    {/*  <div style={{ position: 'absolute', top: 0, right: 10, zIndex: 1, backgroundColor: 'lightGray', padding: 5, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <ArrowDownward fontSize='small' style={{ fontSize: props.screenWidth !== 'xs' ? 12 : 8 }} />
                                    </div> */}
                                    <img src={props.screenWidth === 'xs' && hit.thumbnailURL !== "" ? hit.thumbnailURL : hit.url} style={{ maxWidth: '100%', borderRadius: 4 }} alt='alt' />
                                </div>
                                <Typography variant="body2" color="rgba(0,0,0,0.8)" fontSize={props.screenWidth !== 'xs' ? 13 : 10} fontWeight={500} style={{ paddingTop: 10 }}>
                                    <Highlight attribute="prompt" hit={hit} />

                                </Typography>

                            </CardContent>
                            <CardActions style={{ paddingTop: 0, paddingBottom: 0 }} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                {props.isLoggedIn === true ? <UpdateCard card={hit} updateHits={updateHits} userInfo={props.userInfo} /> : <FavoriteBorder fontSize='small' style={{ cursor: 'pointer', }} onClick={() => {
                                    props.openSnack('warning', 'Please login to favorite collections')
                                }} />}
                                <Typography variant="body2" color="rgba(0,0,0,0.8)" fontSize={props.screenWidth !== 'xs' ? 12 : 10} fontWeight={500}>
                                    {hit.likes}
                                </Typography>
                                <IconButton aria-label="share" style={{ marginLeft: 20 }}>
                                    <RemoveRedEye fontSize='small' />
                                </IconButton>

                                <Typography variant="body2" fontSize={props.screenWidth !== 'xs' ? 12 : 10} color="rgba(0,0,0,0.8)" fontWeight={500} style={{ paddingRight: 20 }}>
                                    {hit.views}
                                </Typography>




                            </CardActions>


                            {props.tools.includes("collage") && (<CardActions disableSpacing style={{ paddingTop: 0, paddingBottom: 0 }} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                {hit.userName !== undefined && (
                                    <UserButton hit={hit} />
                                )}
                                <IconButton onClick={() => {
                                    if (collagePhotos.includes(hit?.url)) {
                                        //filter
                                        const newCollagePhotos = collagePhotos.filter((f) => f !== hit?.url)
                                        setCollagePhotos(newCollagePhotos)


                                    }
                                    else {
                                        const newCollagePhotos = [...collagePhotos, hit?.url]
                                        setCollagePhotos(newCollagePhotos)

                                    }

                                }}>
                                    <AutoAwesomeMosaicIcon style={{ color: collagePhotos.includes(hit?.url) ? 'black' : 'rgba(0,0,0,0.4)', cursor: 'pointer' }} />
                                </IconButton>
                            </CardActions>)}

                            {props.isAuthedUser === true && props.type === 'my' && (
                                <CardActions disableSpacing style={{ paddingTop: 0, paddingBottom: 0 }} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <DeleteButton hit={hit} />


                                    <PublicText hit={hit} />
                                    <ChangePublicSwitch hit={hit} />

                                </CardActions>

                            )}
                        </Paper>



                    </Grid>

                )
            })}

            <Modal title={null} open={isModalOpen} footer={null} bodyStyle={{ padding: 20 }} style={{ fontFamily: 'Inter' }} onCancel={handleCancel} width={'85%'} >
                <Row type="flex"
                    style={{ alignItems: 'center', position: 'relative' }}
                    justify="center"
                >
                    {props.screenWidth !== 'xs' && (<div style={{ width: '100%', position: 'absolute', top: 0, right: 20, zIndex: 2, textAlign: 'right', }}>
                        <FlagImage hit={currentCard} mobile={false} />
                    </div>)}

                    <Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>
                        <CardActions justify='end' style={{ textAlign: 'right' }}>
                            {props.isLoggedIn === true ? <UpdateCard card={currentCard} updateHits={updateHits} userInfo={props.userInfo} /> : <FavoriteBorder fontSize='small' style={{ cursor: 'pointer', }} onClick={() => {
                                props.openSnack('warning', 'Please login to favorite collections')
                            }} />}
                            <Typography variant="body2" color="rgba(0,0,0,0.8)" fontWeight={500}>
                                {currentCard?.likes}
                            </Typography>


                            <IconButton aria-label="share" style={{ paddingLeft: 20 }}>
                                <RemoveRedEye fontSize='small' />
                            </IconButton>
                            <Typography variant="body2" color="rgba(0,0,0,0.8)" fontWeight={500}>
                                {currentCard?.views}
                            </Typography>

                        </CardActions>
                    </Col>

                    <Col xs={1} style={{ textAlign: 'left', position: 'relative', padding: 0, zIndex: 100 }}>

                        {cardIndex !== 0 && (
                            <IconButton size="large" style={{ padding: 0 }} onClick={() => {
                                getNewCard('prev')
                                setEditMode(false)

                            }}>
                                <KeyboardArrowLeft fontSize='large' />
                            </IconButton>
                        )}
                    </Col>
                    <Col xs={22} sm={15} style={{ textAlign: 'center' }} {...handlers}>
                        <div style={{ position: 'relative' }}>

                            {props.screenWidth === 'xs' && (
                                <div style={{
                                    position: 'absolute', top: 10, right: 20, zIndex: 1, padding: 5,
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}>

                                    <FlagImage hit={currentCard} mobile={true} />
                                </div>
                            )}
                            <img src={currentCard?.url} style={{ maxHeight: '65vh', maxWidth: '90%', borderRadius: 4, boxShadow: "2px 3px 3px rgba(0,0,0,0.2)" }} alt="cardImage" />
                        </div>

                    </Col>
                    <Col xs={0} sm={7} style={{}}>

                        <Row gutter={[8, 8]} >
                            {props.isAuthedUser === true && props.type === 'my' && (<Col xs={24} style={{ textAlign: 'right' }}>
                                <IconButton style={{ float: 'right' }} onClick={() => {
                                    setEditMode(!editMode)
                                    if (editMode === true) {
                                        updateHits({ ...currentCard, })
                                        const db = getFirestore();
                                        const docRef = doc(db, 'items', currentCard?.objectID);
                                        updateDoc(docRef, currentCard).then(() => {

                                        }
                                        ).catch((error) => {
                                            console.error("Error updating document: ", error);
                                        }
                                        );
                                    }
                                }}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </Col>)}
                            {!editMode ? <Col xs={24} style={{ textAlign: 'center' }}>
                                <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontWeight={700} display="inline">Prompt:   </Typography>
                                <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} display="inline">{currentCard?.prompt}</Typography>
                                <ContentCopyIcon fontSize='small' style={{ cursor: 'pointer' }} onClick={() => {
                                    navigator.clipboard.writeText(currentCard?.prompt)
                                    props.openSnack('success', 'Copied to clipboard')
                                }} />
                            </Col> :

                                <Col xs={24} style={{ textAlign: 'center' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontWeight={700} display="inline">Prompt:</Typography>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Prompt"
                                        size="large"
                                        onChange={(e) => {
                                            updateCurrentCard('prompt', e.target.value)
                                        }}
                                        value={currentCard?.prompt}
                                        fullWidth
                                        style={{
                                            width: '100%', fontSize: 18, padding: 12, border: '1px solid #ccc', borderRadius: 5,
                                            color: 'black', backgroundColor: 'white', fontFamily: 'inter'
                                        }}
                                    />

                                </Col>

                            }


                            {!editMode ? <Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>
                                <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontWeight={700} display="inline">Maker:   </Typography>
                                <Typography variant="h6" style={{ fontFamily: 'Inter', }} display="inline">{currentCard?.maker.label || currentCard?.maker}</Typography>
                            </Col> :

                                <Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontWeight={700} display="inline">Maker:</Typography>


                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={makers.sort((a, b) => -b.value.localeCompare(a.value))}
                                        getOptionLabel={(option) => option.label}
                                        fullWidth
                                        ChipProps={{ variant: 'outlined', color: 'primary' }}
                                        renderInput={(params) => <TextField {...params} label="Makers" />}
                                        value={currentCard?.maker || null}
                                        onChange={(e, props) => {

                                            updateCurrentCard('maker', props)
                                        }}

                                    />


                                </Col>


                            }

                            {editMode && (
                                <Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontWeight={700} display="inline">Tags:</Typography>


                                    <Autocomplete
                                        disablePortal
                                        multiple
                                        disableCloseOnSelect={true}
                                        id="combo-box-demo"
                                        options={tags.sort((a, b) => -b.value.localeCompare(a.value))}
                                        getOptionLabel={(option) => option.label}
                                        fullWidth
                                        ChipProps={{ variant: 'outlined', color: 'primary' }}
                                        renderInput={(params) => <TextField {...params} label="Tags" />}
                                        value={currentCard?.tags}
                                        onChange={(e, props) => {

                                            const tagLabels = props.map(item => item.label)
                                            const tagValues = props.map(item => item.value)
                                            updateCurrentCard('tags', props)
                                            updateCurrentCard('tagLabels', tagLabels)
                                            updateCurrentCard('tagValues', tagValues)
                                        }}

                                    />


                                </Col>

                            )}
                            {editMode && (
                                <Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontWeight={700} display="inline">Description:</Typography>


                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Description"
                                        size="large"
                                        value={currentCard?.description}
                                        onChange={(e) => {
                                            updateCurrentCard('description', e.target.value)
                                        }}
                                        fullWidth
                                        style={{
                                            width: '100%', fontSize: 18, padding: 12, border: '1px solid #ccc', borderRadius: 5,
                                            color: 'black', backgroundColor: 'white', fontFamily: 'inter'
                                        }}
                                    />


                                </Col>

                            )}
                            {editMode && (
                                <Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontWeight={700} display="inline">Nickname:</Typography>


                                    <OutlinedInput
                                        fullWidth
                                        size='small'
                                        id="outlined-adornment-weight"
                                        value={currentCard?.nickname}
                                        onChange={(e) => {
                                            updateCurrentCard('nickname', e.target.value)
                                        }}
                                    />


                                </Col>

                            )}
                            < Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>
                                {currentCard !== undefined && (<UserButton hit={currentCard} />)}
                            </Col>

                            <Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>
                                <IconButton onClick={async () => {

                                    setDownloading(true)
                                    const blob = await (await fetch(currentCard?.url)).blob();
                                    FileSaver.saveAs(blob, `${currentCard?.prompt || "image"}.png`);
                                    setDownloading(false)



                                }} style={{ position: 'relative', zIndex: 1 }} size="small" color="inherit" aria-label="upload picture" component="span">
                                    {downloading ? <CircularProgress /> : <DownloadingOutlined />}
                                </IconButton>
                            </Col>


                        </Row>
                    </Col>
                    <Col span={1} size="large" style={{}}>
                        {!isLastPage && cardIndex + 1 === hitList.length ?
                            <Button size="small" style={{ textTransform: 'none', fontFamily: 'Inter' }} onClick={() => {


                                showMore();

                            }}>
                                Show More
                            </Button>
                            :
                            isLastPage && cardIndex + 1 === hitList.length ?

                                null

                                :
                                <IconButton style={{ paddingLeft: 0 }} onClick={() => {
                                    getNewCard('next')
                                    setEditMode(false)
                                }}>
                                    <KeyboardArrowRight fontSize='large' />
                                </IconButton>



                        }


                    </Col>

                    <Col xs={24} sm={0} style={{ textAlign: 'center', paddingTop: 10 }}>
                        <Row gutter={[8, 8]}>
                            <Col xs={24} style={{ textAlign: 'center' }}>
                                <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontSize={13} fontWeight={700} display="inline">Prompt:   </Typography>
                                <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontSize={13} display="inline">{currentCard?.prompt}</Typography>
                                <ContentCopyIcon fontSize='small' style={{ cursor: 'pointer' }} onClick={() => {
                                    navigator.clipboard.writeText(currentCard?.prompt)
                                    props.openSnack('success', 'Copied to clipboard')
                                }} />
                            </Col>
                            <Col xs={12} style={{ textAlign: 'center', paddingTop: 10 }}>
                                <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10 }} fontSize={13} fontWeight={700} display="inline">Maker:   </Typography>
                                <Typography variant="h6" style={{ fontFamily: 'Inter', }} display="inline" fontSize={13} >{currentCard?.maker.label || currentCard?.maker}</Typography>
                            </Col>
                            <Col xs={12} style={{ textAlign: 'center', paddingTop: 10 }}>
                                {downloading ? <CircularProgress /> : <Button fullWidth variant='contained' style={{ textTransform: 'none', backgroundColor: 'rgba(0,0,0,0.05)' }} onClick={async () => {

                                    setDownloading(true)
                                    const blob = await (await fetch(currentCard?.url)).blob();
                                    FileSaver.saveAs(blob, `${currentCard?.prompt || "image"}.png`);
                                    setDownloading(false)



                                }} size="small" color="inherit" aria-label="upload picture" component="span">
                                    <Typography variant="h6" style={{ fontFamily: 'Inter', }} fontSize={13} fontWeight={700} display="inline">Download</Typography>
                                </Button>}
                            </Col>
                            <Col xs={24} style={{ textAlign: 'center', paddingTop: 10 }}>

                                {currentCard !== undefined && (<UserButton hit={currentCard} />)}
                            </Col>





                        </Row>
                    </Col>






                </Row>


            </Modal >
            <ScrollLock isActive={lockScreen} />
            <Modal title={null} open={collageOpen} footer={null} bodyStyle={{ padding: 5 }} style={{ fontFamily: 'Inter', padding: 0 }} onCancel={() => {

                setLockScreen(false)
                setTimeout(() => {
                    handleCancel()
                }, 100);
            }} width={props.screenWidth === 'xs' ? '75%' : '50%'} >
                <Row>
                    <Col span={24}>
                        <ImageCollage screenWidth={props.screenWidth} openSnack={props.openSnack} />
                    </Col>
                </Row>



            </Modal>



            {
                collagePhotos.length > 0 && props.tools.includes("collage") && (<Grid item xs={12} style={{ position: 'fixed', bottom: '15vh', right: '5vh' }} >

                    <Button onClick={() => {
                        setCollageOpen(true)
                        setLockScreen(true)

                    }} style={{ fontFamily: 'Inter', textTransform: 'none', width: '100%', height: '100%', fontSize: 12, backgroundColor: '#3f51b5', color: 'white' }} variant="contained" color="primary" disableElevation>
                        Create Collage
                    </Button>

                </Grid>)
            }
            {
                !isLastPage && (<Grid item xs={12} >

                    <Button style={{ fontFamily: 'Inter', textTransform: 'none', width: '100%', height: '100%', borderRadius: 0, backgroundColor: '#f5f5f5', color: '#000000', fontWeight: 700 }}
                        onClick={() => {
                            showMore()
                        }}>Show More</Button>

                </Grid>)
            }
            {
                isLastPage && (<Grid item xs={12} style={{ textAlign: 'center' }} >

                    <Typography style={{ fontFamily: 'Inter', textTransform: 'none', width: '100%', height: '100%', borderRadius: 0, backgroundColor: '#f5f5f5', color: '#000000', fontWeight: 700, textAlign: 'center' }}>
                        End of the line sorry! No more images.
                    </Typography>

                </Grid>)
            }


        </Grid >

    )


}



function Search(props) {

    const { id } = useParams();

    const [preferencesModalOpen, setPreferencesModalOpen] = useState(false);

    const filters = props.filter === 'collections' ? props?.collectionImages.map((p, index) => {
        return `key:${p}`
    }) :
        props.type === 'favorites' && props?.userInfo?.likedImages !== undefined ?
            props?.userInfo?.likedImages.map((p, index) => {
                return `key:${p}`
            }) :

            props.type === 'favorites' && props?.userInfo?.likedImages === undefined ? `key:${1}` : `creator:${id}`;



    const filter = props.type === 'main' ? "public:true" : props.type === 'favorites' ? '' : `creator:${id}`;



    const filterArray = [filters];

    const [gridSize, setGridSize] = useState(props.screenWidth === 'xs' ? 2 : 4)
    useEffect(() => {
        if (props.screenWidth === 'xs') {
            setGridSize(2)
        } else if (props.screenWidth === 'sm') {
            setGridSize(3)
        } else {
            setGridSize(5)
        }
    }, [props.screenWidth]) // eslint-disable-line react-hooks/exhaustive-deps


    console.log(props)
    return (
        <StickyContainer>
            {/* Other elements can be in between `StickyContainer` and `Sticky`,
        but certain styles can break the positioning logic used. */}

            <InstantSearch
                indexName={props.index}
                searchClient={searchClient}
                stalledSearchDelay={200}
            >
                <Row style={{}}>
                    {/* <Col xs={0} sm={4} style={{ paddingRight: 30 }}>
                    <Typography variant="h6" style={{}}>Filters</Typography>
                    <Divider />
                    <RefinementList attribute="maker" style={{ fontFamily: 'Inter', paddingTop: 10 }} />
                </Col> */}
                    <Col xs={24} style={{}}>
                        <Row align='middle'>
                            <Col xs={20} sm={22}>
                                <Sticky topOffset={80}>
                                    {({
                                        style,

                                        // the following are also available but unused in this example
                                        isSticky,
                                        wasSticky,
                                        distanceFromTop,
                                        distanceFromBottom,
                                        calculatedHeight
                                    }) => (
                                        <header style={{ ...style, paddingTop: 10, zIndex: 100 }}>
                                            {props.type === 'main' ? <SearchBox
                                                searchAsYouType={true}
                                                autoFocus={false}
                                            /> : props.type === 'my' && props.imagesLimit > props.currentImages ? <SearchBox
                                                searchAsYouType={true}
                                                autoFocus={false}
                                            /> :
                                                <Typography variant="body1" style={{ fontFamily: 'Inter', paddingTop: 10, textAlign: 'center' }}>
                                                    You have reached your image limit. Upgrade to search through your images.</Typography>}
                                        </header>
                                    )}
                                </Sticky>
                            </Col>
                            <Col xs={4} sm={2}>
                                <IconButton style={{ float: 'right' }} onClick={() => { setPreferencesModalOpen(true) }}>
                                    <MoreHorizIcon />
                                </IconButton>

                            </Col>

                        </Row>

                        <Modal title={null} open={preferencesModalOpen} footer={null} bodyStyle={{ padding: 20 }} style={{ fontFamily: 'Inter', padding: 0 }} onCancel={() => { setPreferencesModalOpen(false) }}
                            width={props.screenWidth === 'xs' ? '75%' : '50%'} >
                            <Row>
                                <Col span={6}>
                                    <Typography variant="h6" style={{ fontFamily: 'Inter', paddingTop: 10, textAlign: 'center' }}>Grid</Typography>
                                </Col>
                                <Col span={18}>
                                    <Slider
                                        defaultValue={gridSize}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        marks
                                        min={1}
                                        max={6}
                                        onChange={(e, value) => { setGridSize(value) }}
                                    />
                                </Col>
                            </Row>
                        </Modal>





                        <Configure

                            hitsPerPage={36}

                            analytics={false}
                            filters={filter}
                            facetFilters={props.filter === 'collections' ? filterArray : props.type === 'favorites' ? filterArray : ''}

                        />
                        <Col xs={24} style={{}}>

                            <Row style={{ paddingTop: 15 }}>
                                <SortBy
                                    items={[
                                        { label: 'Date (asc)', value: 'date_asc' },
                                        { label: 'Date (desc)', value: 'date_desc' },
                                        { label: 'Public', value: 'public' },
                                    ]}
                                />
                                <CustomInfiniteHits {...props} updateCollectionImages={props.updateCollectionImages} openSnack={props.openSnack} gridSize={gridSize} userInfo={props.userInfo}
                                />
                            </Row>
                        </Col>

                    </Col>
                </Row>



            </InstantSearch>
        </StickyContainer>

    );
}


export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            uid: this.props.uid,
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            card: null,
            collagePhotos: [],
            isCollageOpen: false,
            isLoggedIn: undefined,
            isAuthedUser: this.props.isAuthedUser,
            profileid: null,
            index: this.props.index,
            tools: this.props.tools,
            filter: this.props.filter,
            collectionImages: this.props.collectionImages,
            item: this.props.item,
            imagesLimit: 100,
            currentImages: 0
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.isAuthedUser !== prevProps.isAuthedUser) {
            this.setState({ isAuthedUser: this.props.isAuthedUser })
        }
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item })
        }


    }
    async componentDidMount() {

        const auth = getAuth();



        if (auth.currentUser !== null) {
            this.setState({ isLoggedIn: true })

            const auth = getAuth();
            const user = auth.currentUser;

            this.setState({ user })
            const db = getFirestore();
            const activeRef = getDocs(collection(db, "customers", this.state.uid, 'subscriptions'), where("status", "in", ["trialing", "active"]));
            const q = query(collection(db, "items"), where("creator", "==", this.state.uid));
            const querySnapshot = await getDocs(q);
            const images = []


            this.setState({ currentImages: querySnapshot.size })

            activeRef.then((querySnapshot) => {
                querySnapshot.forEach(async (snap) => {


                    const subscription = snap.data();
                    const priceId = subscription.items[0].price.id;
                    const priceData = (await getDoc(subscription.price)).data();
                    const item = { ...subscription, id: snap.id, metadata: priceData.metadata, priceId: priceId }
                    this.setState({ activeSubscription: item, priceId, })



                    const imagesLimit = priceId === 'price_1Lz3SNKNkNgX2BexPg4EgZC1' ? 350 :
                        priceId === 'price_1Lz3SNKNkNgX2BextzRvIg6F' ? 99999 : 100;

                    this.setState({ imagesLimit })

                });
            }
            ).catch((error) => {
                console.log("Error getting documents: ", error);
            }
            );

        }
        else {
            this.setState({ isLoggedIn: false })
        }

    }

    setCurrentCard = (card) => {
        this.setState({ currentCard: card })

    }



    render() {

        const { uid, type, screenWidth, userInfo, collagePhotos, isCollageOpen, isLoggedIn, index, tools, filter, collectionImages, isAuthedUser, item, currentImages, imagesLimit } = this.state;





        return (
            <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : filter === 'collections' ? 0 : 30, paddingTop: 20, paddingBottom: 30, paddingRight: screenWidth === 'xs' ? 0 : 30, position: 'relative', zIndex: 2, }}>
                <Row gutter={[0, 0]}>


                    <Col span={24}>
                        <Search uid={uid} type={type} setCurrentCard={this.setCurrentCard} screenWidth={screenWidth} userInfo={userInfo} isLoggedIn={isLoggedIn} isAuthedUser={isAuthedUser}
                            openSnack={this.props.openSnack} isCollageOpen={isCollageOpen} index={index} tools={tools} filter={filter}
                            collectionImages={item?.images} updateCollectionImages={this.props.updateCollectionImages} currentImages={currentImages} imagesLimit={imagesLimit}
                        />

                    </Col>
                </Row>




            </Col>

        );
    }
} 