import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider } from '@mui/material';
import Gallery from './gallery';


export default class MyGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            uid: this.props.uid,
            type: this.props.type,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
           
        }
        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
       
    }

    render() {

        const { account, uid, type, screenWidth, userInfo } = this.state;

        return (
            <Row style={{}}>
                <Col span={24}>
                    <Row align="middle">
                        <Col xs={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h4">My Gallery</Typography>
                        </Col>

                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Gallery uid={uid} type={type} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} />
                    </Row>
                </Col>



            </Row>
        );
    }
} 