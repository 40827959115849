import { Button, CardActions, CardContent, CircularProgress, Grid, IconButton, Paper, Switch, TextareaAutosize, Typography } from '@mui/material';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';


import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';


import { DeleteForever, FavoriteBorder, KeyboardArrowLeft, KeyboardArrowRight, RemoveRedEye } from '@mui/icons-material';
import { Configure, Highlight, InstantSearch, SearchBox, useInfiniteHits } from 'react-instantsearch-hooks-web';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSwipeable } from 'react-swipeable';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, getDoc, deleteDoc, updateDoc, setDoc, arrayUnion, query, collectionGroup, getDocs, collection, where, onSnapshot } from 'firebase/firestore';
import SearchAll from './searchAll';


var moment = require('moment-timezone');





export default class CollectionCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            uid: this.props.uid,
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            currentImages: this.props.currentImages,
            imagesLimit: this.props.imagesLimit,
            card: null,
            collagePhotos: [],
            isCollageOpen: false,
            item: {}
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.currentImages !== prevProps.currentImages) {
            this.setState({ currentImages: this.props.currentImages })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ imagesLimit: this.props.imagesLimit })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        const { uid, userInfo } = this.state;
        const key = uuid()
        this.setState({
            item: {
                collectionCreateDate: moment().format('x'),
                date: Number(moment().format('x')),
                downloadURL: '',
                url: '',
                name: '',
                nickname: '',
                preview: '',
                key: key,
                metadata: '',
                likes: 0,
                views: 0,
                public: false,
                tags: [],
                tagLabels: [],
                tagValues: [],
                customTags: [],
                description: '',
                maker: '',
                prompt: '',
                comments: [],
                images: [],
                creator: uid,
                userName: userInfo.displayName,
                creating: false,
                reports: [],
                reported: false


            }
        })

    }

    setCurrentCard = (card) => {
        this.setState({ currentCard: card })

    }

    updateCollectionImages = (images) => {
        this.setState({ item: { ...this.state.item, images: images } })


    }

    updateItem = (boo, val) => {
        let item = this.state.item;
        item[boo] = val;
        this.setState({ item: item });

    }

    render() {

        const { uid, type, screenWidth, userInfo, collagePhotos, isCollageOpen, item, creating, imagesLimit, currentImages } = this.state;

        const CreateCollectionButton = () => {
            const db = getFirestore();

            return (
                creating ? <CircularProgress /> : <Button style={{
                    fontFamily: 'Inter', textTransform: 'none', backgroundColor: '#3b5998',
                    color: 'white', fontWeight: 700
                }}
                    _active={{
                        bg: 'gray.800',
                        transform: 'scale(0.95)'
                    }} onClick={async () => {
                        this.setState({ creating: true })
                        if (item.name.length > 0) {
                            if (item.images.length > 0) {


                                const docRef = doc(db, 'items', item.images[0])
                                const docSnap = await getDoc(docRef);


                                if (docSnap.exists()) {

                                    item.url = docSnap.data().url;
                                    console.log(item.url)

                                    await setDoc(doc(db, "collections", item.key), item).then(() => {
                                        const key = uuid()
                                        this.setState({
                                            item: {
                                                collectionCreateDate: moment().format('x'),
                                                date: moment().format('x'),
                                                downloadURL: '',
                                                url: '',
                                                name: '',
                                                nickname: '',
                                                preview: '',
                                                key: key,
                                                metadata: '',
                                                likes: 0,
                                                views: 0,
                                                public: false,
                                                tags: [],
                                                tagLabels: [],
                                                tagValues: [],
                                                customTags: [],
                                                description: '',
                                                maker: '',
                                                prompt: '',
                                                comments: [],
                                                images: [],
                                                creator: uid,
                                                userName: userInfo.displayName


                                            }
                                        })
                                    }).then(() => {
                                        //if last image return to my-gallery
                                        this.props.openSnack('success', 'Collection Created!')
                                        this.setState({ creating: false })
                                    }).then(() => {
                                        this.props.closeModal()
                                    })
                                } else {
                                    // doc.data() will be undefined in this case
                                    console.log("No such document!");
                                }


                            } else {
                                this.props.openSnack('error', 'Please add images to your collection!')
                                this.setState({ creating: false })
                            }
                        }
                        else {
                            this.props.openSnack('error', 'Please give your collection a name!')
                            this.setState({ creating: false })
                        }
                    }}

                >Create Collection</Button>
            )
        }




        return (
            <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 20, paddingBottom: 30, paddingRight: screenWidth === 'xs' ? 0 : 30, }}>
                <Row gutter={[8, 8]}>


                    <Col span={24}>
                        <Row align="middle" justify='center'>
                            <Col xs={16} style={{ paddingTop: 30, paddingBottom: 15, }}>
                                <Typography variant="h4">Create a collection!</Typography>
                            </Col>
                            <Col xs={8} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                                {imagesLimit > currentImages ? <CreateCollectionButton /> :
                                    <Typography variant="body1" style={{ fontFamily: 'Inter', paddingTop: 10, textAlign: 'center' }}>
                                        You have reached your image limit. Upgrade to create collection.</Typography>}


                            </Col>


                            <Col span={24} style={{ paddingTop: 20 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography variant="h6">Name<span style={{ color: 'red' }}>*</span></Typography>
                                    </Col>
                                    <Col span={24} >
                                        <TextareaAutosize
                                            aria-label="empty textarea"
                                            placeholder="Name"
                                            size="large"
                                            onChange={(e) => {
                                                this.updateItem('name', e.target.value)
                                            }}
                                            value={item.name}
                                            fullWidth
                                            style={{
                                                width: '100%', fontSize: 18, padding: 12, border: '1px solid #ccc', borderRadius: 5,
                                                color: 'black', backgroundColor: 'white', fontFamily: 'inter'
                                            }}
                                        />

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} style={{ paddingTop: 20 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography variant="h6">Public</Typography>
                                    </Col>
                                    <Col span={24} >
                                        <Switch
                                            checked={item.public}
                                            onChange={(e) => {
                                                this.updateItem('public', e.target.checked)


                                            }}
                                            color="primary"
                                            name="checkedB"
                                        />

                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24} style={{ paddingTop: 20 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography variant="h6">Description (optional)</Typography>
                                    </Col>
                                    <Col span={24}>
                                        <TextareaAutosize
                                            aria-label="empty textarea"
                                            placeholder="Description"
                                            size="large"
                                            value={item.description}
                                            onChange={(e) => {
                                                this.updateItem('description', e.target.value)
                                            }}
                                            fullWidth
                                            style={{
                                                width: '100%', fontSize: 18, padding: 12, border: '1px solid #ccc', borderRadius: 5,
                                                color: 'black', backgroundColor: 'white', fontFamily: 'inter'
                                            }}
                                        />

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} style={{ paddingTop: 20 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography variant="h6">Images: {item.images?.length}</Typography>
                                    </Col>

                                </Row>
                            </Col>



                        </Row>
                    </Col>




                    <Col span={24}>
                        <SearchAll uid={uid} type={'my'} setCurrentCard={this.setCurrentCard} screenWidth={screenWidth} userInfo={userInfo} item={item}
                            openSnack={this.props.openSnack} updateCollectionImages={this.updateCollectionImages} isCollageOpen={isCollageOpen}
                        />
                    </Col>

                </Row>




            </Col>

        );
    }
} 