import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Photo from "./photo";
import download from "downloadjs";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import "./styles.css";
import { Col, Row } from "antd";
import ScrollLock from "react-scrolllock";
/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement(item => <Photo {...item} style={{ padding: 0 }} />);

const SortableGallery = SortableContainer(({ items, screenWidth, type }) => {

    return (

        < Gallery photos={items} style={{ padding: 0 }} renderImage={props => <SortablePhoto {...props} type={type} screenWidth={screenWidth} />} />

    )
});


export default function SortableGalleryPhotos(props) {
    const [items, setItems] = useState(props.photos);
    const [lockScreen, setLockScreen] = useState(false);


    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems(arrayMoveImmutable(items, oldIndex, newIndex));
    };

    useEffect(() => {
        setLockScreen(props.showModal)

    }, [props.showModal])


    if (props.screenWidth === 'xs') {

        console.log(lockScreen)

        return (
            <Row>
                <Col span={24} >
                    <SortableGallery items={items} onSortEnd={onSortEnd} axis={"xy"} screenWidth={props.screenWidth} />
                </Col>
                <ScrollLock isActive={lockScreen} />
                <div style={{ position: 'absolute', zIndex: -10, left: 10000, width: '1024px' }} span={24} id="my-node" >
                    <SortableGallery items={items} onSortEnd={onSortEnd} axis={"xy"} screenWidth={props.screenWidth} />

                </div>
            </Row>


        )
    }
    else {



        return (<Row>
            <Col span={24} id="my-node" >
                <SortableGallery items={items} onSortEnd={onSortEnd} axis={"xy"} screenWidth={props.screenWidth} />
            </Col></Row>

        );
    }
}








