import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Gallery from './gallery';
import CollectionsGallery from '../user/collectionsGallery';


export default class MainGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            uid: this.props.uid,
            type: 'main',
            galleryType: 'images', // images or collections
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }


    }
    componentDidMount() {
        window.scrollTo(0, 0)
        console.log('loading')
    }

    openSnack = (message, severity) => {

        this.props.openSnack(message, severity)
    }

    render() {

        const { account, uid, type, screenWidth, userInfo, galleryType } = this.state;

        return (
            <Row style={{}}>
                <Col span={24}>
                    <Row align="middle">
                        <Col xs={24} style={{ paddingLeft: screenWidth === 'xs' ? 10 : 30, paddingTop: 30, paddingBottom: 15, fontFamily: 'Inter' }}>
                            <Typography variant="h6" style={{ color: 'rgba(0,0,0,0.5)' }}>Get Inspired. Build your profile. Explore AI design work.</Typography>
                        </Col>



                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 5 : 30, fontFamily: 'Inter', }}>

                            <ToggleButtonGroup
                                style={{ textTransform: 'none', fontFamily: 'Inter', fontWeight: 700, fontSize: 12 }}
                                color="primary"
                                aria-label="Platform"
                                value={galleryType}
                                onChange={(e, newAlignment) => {
                                    this.setState({ galleryType: e.target.value })
                                }}
                            >
                                <ToggleButton value="images" style={{ textTransform: 'none', fontFamily: 'Inter', fontWeight: 700, fontSize: 12 }} >Images</ToggleButton>
                                <ToggleButton value="collections" style={{ textTransform: 'none', fontFamily: 'Inter', fontWeight: 700, fontSize: 12 }} >Collections</ToggleButton>
                            </ToggleButtonGroup>

                        </Col>

                      

                        {galleryType === 'images' && (
                            <Gallery  type={type}  openSnack={this.props.openSnack} screenWidth={screenWidth} userInfo={this.props.userInfo}  index="itemSearch"
                                tools={['collage',]} />)}
                        {galleryType === 'collections' && (<CollectionsGallery  type={type}  screenWidth={screenWidth} userInfo={this.props.userInfo} openSnack={this.props.openSnack} index="collectionsSearch" tools={['collage',]} />)}


                    </Row>
                </Col>



            </Row>
        );
    }
} 