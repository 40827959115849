import React from "react";

const imgWithClick = { cursor: "pointer" };

const Photo = ({ index, onClick, photo, margin, direction, top, left, screenWidth, type }) => {
  const imgStyle = { margin: margin };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;




  }
  imgStyle.padding = 0;
  imgStyle.margin = 0;
  console.log(type)

  if (screenWidth === 'xs') {
    imgStyle.width = '45%';
    imgStyle.height = 'auto';
  }





  const handleClick = event => {
    onClick(event, { photo, index });
  };

  return (
    <img cors="anonymous" crossOrigin="anonymous"
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}

      {...photo}
      onClick={onClick ? handleClick : null}
      alt="img"

    />
  );
};

export default Photo;
