import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

export default class FooterBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            account: this.props.account,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.account !== prevProps.account) {
            this.setState({ account: this.props.account })
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        const { account } = this.state;

        const HomeButton = () => {
            let navigate = useNavigate();

            return (
                <Button variant="link" color="primary" display="indline" style={{ padding: 10, textTransform: 'none', color: '#3b5998', fontSize: 16, padding: 6, }}

                    onClick={() => {
                        this.setState({ drawerVisible: false })
                        navigate('/')
                    }}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/cloud-prompt-ba4db.appspot.com/o/images%2F1211af-436d-aae1-87aa-3e2e6d53fdb?alt=media&token=51856184-66d2-41a2-b66a-cd681d620226"
                        style={{ height: 50, width: 50, }} />
                </Button>
            )
        }
        const BetaButton = () => {
            let navigate = useNavigate();

            return (

                <img src="https://firebasestorage.googleapis.com/v0/b/cloud-prompt-ba4db.appspot.com/o/images%2F84f7d0-64a-70f5-3be7-2d34d3cb755?alt=media&token=ddc45b4b-63e3-440b-957a-95f52dfd3153"
                    style={{ height: 40, width: 40, }} />

            )
        }

        return (
            <Row style={{}}>
                <Col span={23}>
                    <Row style={{ paddingBottom: 50 }} >
                        <Col xs={0} sm={6} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <HomeButton />
                            <BetaButton />
                        </Col>
                        <Col sm={12} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>
                            <Typography variant="h6">Quick links</Typography>

                            <Link to="/about" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{ color: '#3b5998' }}>About</Typography>
                            </Link>
                            <Link to="/roadmap" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{ color: '#3b5998' }}>Roadmap</Typography>
                            </Link>

                            <Link to="/privacy" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{ color: '#3b5998' }}>Privacy</Typography>
                            </Link>
                            <Link to="/terms" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{ color: '#3b5998' }}>Terms</Typography>
                            </Link>
                            <Link to="/pricing" style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" style={{ color: '#3b5998' }}>Pricing</Typography>
                            </Link>



                        </Col>

                    </Row>
                </Col>

            </Row>
        );
    }
} 