import React, { useCallback, useMemo, useRef } from "react";
import download from "downloadjs";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

import "./styles.css";
import { saveAs } from "file-saver";

export default function DownloadTest() {
    const ref = useRef(null);

    const handleClick = useCallback(async () => {
        if (ref.current) {
            saveAs(await toPng(ref.current), "test.jpg");

        }
    }, [ref?.current]);

    return (
        <div className="App">
            <div ref={ref} className="test">
                <div className="c">Hello World</div>
            </div>
            <button onClick={() => handleClick()}>Click</button>
        </div>
    );
}