import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider, CircularProgress } from '@mui/material';
import { getAuth, sendPasswordResetEmail, updateEmail, updateProfile } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, where, onSnapshot } from 'firebase/firestore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions'
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import moment from 'moment';
import AboutPanel from '../private/user/aboutPanel';
import Gallery from '../private/pages/gallery';
import CollectionsGallery from '../private/user/collectionsGallery';

const stripePromise = loadStripe('pk_test_51Ls85PKNkNgX2BexkbwAvdJ8RE46JG4YGawZhaOcII2zLQ1GdBbNvkOf9anMnMgTJOUvNRGQjjfhMLV5vRTTx69c00da00c7oS');

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: {},
            user: {},
            uid: this.props.uid,
            products: [],
            activeSubscription: undefined,
            disaplayName: '',
            email: '',
            accessingPortal: false,
            value: 0,
            profileid: undefined
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.profileid !== prevProps.profileid) {
            this.setState({ profileid: this.props.profileid })
            this.findProfile(this.props.profileid)
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)



    }

    handleChange = (newValue) => {
        this.setState({ value: newValue })
    }

    findProfile = (profileid) => {
        const db = getFirestore();
        const userRef = doc(db, "customers", profileid);
        getDoc(userRef).then((doc) => {
            if (doc.exists()) {
                this.setState({ userInfo: doc.data() })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    render() {

        const { user, products, activeSubscription, displayName, email, accessingPortal, value, userInfo, screenWidth, profileid } = this.state;


        const AboutPanelBlock = (props) => {
            const { id } = useParams();
            const userInfo = { ...props.userInfo, uid: id };
            return (
                <AboutPanel userInfo={userInfo} loggedin={false} />
            )
        }

        const WelcomePageInfo = () => {



            return (
                <Typography variant="h4" component="h4" gutterBottom>
                    Welcome to profile page
                </Typography>
            )
        }

        const ProfileCheck = () => {
            let { id } = useParams();

            if (id !== profileid) {

                this.setState({ profileid: id })
                this.findProfile(id)
            }
            return null;
        }


        const sidePadding = 30;

        return (
            <Row style={{}}>
                <Col span={24}>
                    <Row align="middle">
                        <ProfileCheck />
                        <Col sm={24} style={{ paddingLeft: screenWidth === 'xs' ? 20 : 50, paddingTop: 30, paddingBottom: 15, height: '20vh', }}>
                            <Typography variant="h4" component="h4" gutterBottom>
                                Welcome to {userInfo.displayName}'s profile page
                            </Typography>

                        </Col>
                        <Col span={24} style={{ padding: screenWidth === 'xs' ? 15 : 25 }}>
                            <Box sx={{ width: '100%', fontFamily: 'Inter' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={(e, newValue) => {
                                        this.handleChange(newValue)
                                    }} aria-label="basic tabs example">
                                        <Tab label="Images" {...a11yProps(0)} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />
                                        <Tab label="Collections" {...a11yProps(1)} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />
                                        <Tab label="About" {...a11yProps(2)} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />


                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <Row>


                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 15, paddingTop: 30, }}>
                                            <Typography variant="h3">Images</Typography>

                                        </Col> 
                                        <Gallery profileid={profileid} uid={user.uid} type={'my'} screenWidth={screenWidth} openSnack={this.props.openSnack} index="itemSearch" isAuthedUser={false} 
                                            tools={['collage',]} />
                                    </Row>
                                </TabPanel>
                                <TabPanel value={value} index={1}>




                                    <CollectionsGallery profileid={profileid} uid={user.uid} type={'main'} index="collectionsSearch" isAuthedUser={false} 
                                        screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} />



                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Row>


                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 15, paddingTop: 30, }}>
                                            <Typography variant="h3">About</Typography>

                                        </Col>
                                        <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 15, paddingTop: 30, }}>
                                            <AboutPanelBlock user={user} userInfo={userInfo} openSnack={this.props.openSnack} />
                                        </Col>
                                    </Row>

                                </TabPanel>


                            </Box>
                        </Col>
                        <Col xs={16} sm={20} style={{ paddingLeft: 30, paddingTop: 30, paddingBottom: 15 }}>

                        </Col>




                    </Row>
                </Col >
            </Row >
        );
    }
} 