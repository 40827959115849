import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail, updateEmail, updateProfile } from "firebase/auth";
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider, CircularProgress, Grid, List, ListItem, ListItemText } from '@mui/material';
import { Col, Row, } from 'antd';
import { doc, getFirestore, updateDoc, addDoc, collection, onSnapshot } from 'firebase/firestore';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import firebase from 'firebase/compat/app';

import { Stripe, loadStripe } from '@stripe/stripe-js';
import axios from 'axios';





export async function createCheckoutSession(uid, price, id) {
    const db = getFirestore();


    const docRef = await addDoc(collection(db, "customers", uid, 'checkout_sessions'), {
        price,
        // This can be removed if you don't want promo codes
        allow_promotion_codes: true,
        success_url: `${window.location.origin}/profile/${id}`,
        cancel_url: `${window.location.origin}/profile/${id}`,
    });
    console.log("Document written with ID: ", docRef.id);
    let stripePromise;



    const unsub = onSnapshot(doc(db, "customers", uid, 'checkout_sessions', docRef.id), (doc) => {
        console.log("Current data: ", doc.data());
        const { error, url } = doc.data();
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
    });




}



export default function SettingsPanel({ activeSubscription, screenWidth, products, user, openSnack }) {

    const [accessingPortal, setAccessingPortal] = useState(false);
    const [isCheckoutLoading, setCheckoutLoading] = useState(false);
    const [activePlan, setActivePlan] = useState(activeSubscription);


    let { id } = useParams();


    const sidePadding = 30;


    return (
        <Row gutter={[8, 8]}>


            <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 30, }}>
                <Typography variant="h3">Your Settings</Typography>

            </Col>
            <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 30, }}>
                <Row>
                    <Col span={14}>
                        {activePlan && (<Typography variant={screenWidth === 'xs' ? 'h6' : 'h4'}>Your Subscription</Typography>)}
                    </Col>
                    <Col span={10} style={{ textAlign: 'right' }}>
                        {accessingPortal === false ? <Button size={screenWidth === 'xs' ? 'small' : 'medium'} style={{ textTransform: 'none', backgroundColor: '#3b5998', color: 'white' }}

                            onClick={async () => {

                                setAccessingPortal(true)

                                const functionRef = firebase.default.functions().httpsCallable('ext-firestore-stripe-payments-createPortalLink');

                                const { data } = await functionRef({ returnUrl: `${window.location.origin}/profile/${id}`, });

                                window.location.assign(data.url);

                                setAccessingPortal(false)






                            }}>Access customer portal</Button> :
                            <CircularProgress />}
                    </Col>
                </Row>


            </Col>

            <Col span={24} style={{ paddingLeft: 30, }}>
                {activePlan !== undefined && activePlan.metadata !== undefined && (<Typography variant="h6">{activePlan.metadata.nickname}</Typography>)}
                {activePlan && (<Typography variant="h6">And you are paying</Typography>)}
                {activePlan !== undefined && activePlan.metadata !== undefined && (<Typography variant="h6">${activePlan.items[0].price.unit_amount / 100}/mo</Typography>)}


                {activePlan && (<Typography variant="h6">Your current period started on {moment(activePlan?.current_period_start.seconds, 'X').format('LL')}</Typography>)}

                {activePlan && (<Typography variant="h6">Your current period ends on {moment(activePlan?.current_period_end.seconds, 'X').format('LL')}</Typography>)}
                {activePlan && (<Typography variant="h6">Renewing: {activePlan?.cancel_at_period_end ? "No" : "Yes"}</Typography>)}


            </Col>




            <Col span={24} style={{ paddingLeft: 30, paddingTop: 30, }}>

                <Grid container spacing={2}>
                    {[...products, { unit_amount: 0, description: 'Free', }].sort((a, b) => a.unit_amount - b.unit_amount).map((product) => {
                        console.log(activePlan)
                        return (
                            <Grid item xs={12} sm={4} md={4} lg={4} >
                                <Paper elevation={4} style={{ padding: 20, textAlign: 'center' }}>

                                    <Typography variant="h3" fontWeight={300} style={{ paddingBottom: 10 }}>${product?.unit_amount / 100}/mo</Typography>
                                    <Typography variant="body1" style={{ paddingBottom: 10 }}>{product?.description}</Typography>
                                    {isCheckoutLoading ? <CircularProgress /> :

                                        product.priceId === undefined ?
                                            <Typography variant='body1'></Typography> :


                                            activePlan?.priceId === product.priceId ?
                                                <Typography variant='body1'>You are already subscribed to this plan</Typography> :

                                                <Button size={screenWidth === 'xs' ? 'small' : 'medium'} style={{ textTransform: 'none', backgroundColor: '#3b5998', color: 'white' }}
                                                    onClick={() => {
                                                        console.log(product)
                                                        console.log(activePlan)

                                                        if (activePlan !== undefined) {

                                                            const proxyUrl = "https://mycorslake.herokuapp.com/";
                                                            axios.post(proxyUrl + 'https://us-central1-cloud-prompt-ba4db.cloudfunctions.net/stripeFunctions/updateSubscription', {
                                                                subscriptionId: activePlan.id,
                                                                priceId: product.priceId,


                                                            }).then(async (response) => {
                                                                // handle success

                                                                console.log(response)

                                                                if (response.data.msg === 'success') {
                                                                    //update active plan priceId and new unit amount
                                                                    setActivePlan({ ...activePlan, priceId: product.priceId, items: [{ price: { unit_amount: product.unit_amount } }] })
                                                                    openSnack('success', 'Subscription updated successfully')

                                                                }


                                                            }).catch((error) => {
                                                                console.log(error)
                                                            })
                                                        }
                                                        else {
                                                            setCheckoutLoading(true);
                                                            createCheckoutSession(user.uid, product?.priceId, id);
                                                        }


                                                    }}


                                                    isLoading={isCheckoutLoading}
                                                    _hover={{ bg: 'gray.700' }}
                                                    _active={{
                                                        bg: 'gray.800',
                                                        transform: 'scale(0.95)'
                                                    }}
                                                >
                                                    {activePlan === undefined ? `Subscribe` :

                                                        activePlan?.priceId === 'price_1Lz3SNKNkNgX2BextzRvIg6F' ? `Downgrade to ${product?.description}` :
                                                            activePlan?.priceId === 'price_1Lz3SNKNkNgX2BexPg4EgZC1' ? `Upgrade to ${product?.description}` :
                                                                `${product?.priceId === undefined ? 'Downgrade' : 'Upgrade'} to ${product?.description}`}




                                                </Button>}

                                    <List >
                                        <ListItem disablePadding>
                                            <ListItemText primary={product?.description === 'Basic' ? 'Up to 350 images' : product?.description === 'Pro' ? 'Unlimited images' :
                                                'This is the free tier and you are limited to 100 photos'} />
                                        </ListItem>

                                        <ListItem disablePadding>
                                            <ListItemText primary={['Basic', 'Pro'].includes(product?.description) && ("Premium support & request features")} />
                                        </ListItem>

                                    </List>


                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>




            </Col>
        </Row>
    );


}
