
import { Alert, CircularProgress, Divider, Drawer, Fab, IconButton, Slide, Snackbar, Typography } from '@mui/material';
import { Col, Layout, Row } from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainGallery from '../private/pages/mainGallery';
import MyGallery from '../private/pages/myGallery';
import Upload from '../private/pages/upload';
import Profile from '../private/user/profile';
import AboutPage from '../public/about';
import Privacy from '../public/privacy';
import Terms from '../public/terms';
import FooterBlock from './footer';
import SignedInHeader from './signedInHeader';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import RoadMap from '../public/roadMap';
import Pricing from '../public/pricing';




const { Header, Content, Sider, Footer } = Layout;

export default class SignedInRouting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            openDrawer: false,
            openTools: false,
            uid: this.props.uid,
            loadScene: true,
            openSnack: false,
            snackType: '',
            snackDescription: '',
            pathname: '',
            loading: true,
            about: null,
            roadMap: null
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })


        }

    }


    async componentDidMount() {
        const auth = getAuth();

        //get uid of auth
        const uid = auth.currentUser?.uid;
        this.setState({ uid: uid })

        const db = getFirestore();
        const docRef = doc(db, "aboutPageInfo", "1");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {

            const info = convertFromRaw(docSnap.data().aboutPageInfo)
            this.setState({ about: info });

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
        const docRefRoad = doc(db, "roadmap", "1");
        const docSnapRoad = await getDoc(docRefRoad);
        if (docSnapRoad.exists()) {

            const info = convertFromRaw(docSnapRoad.data().pageInfo)
            this.setState({ roadMap: info });

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }


    }


    closeSnack = () => {
        this.setState({ openSnack: false, snackType: '', snackDescription: '' })
    }
    openSnack = (type, description) => {
        this.setState({ openSnack: true, snackType: type, snackDescription: description })
    }

    updateUserInfo = (boo, val) => {
        const { userInfo } = this.state;
        userInfo[boo] = val;
        this.setState({ userInfo })

    }



    render() {
        const { openDrawer, screenWidth, openTools, loadScene, uid, roadMap,
            userInfo, openSnack, snackType, snackDescription, account, pathname, loading, company, about } = this.state;





        const GetPath = () => {
            let newLocation = useLocation()
            if (newLocation.pathname !== pathname) {
                this.setState({ pathname: newLocation.pathname, loadScene: true })
                setTimeout(() => {
                    this.setState({ loadScene: false })
                }, 250);
            }
        }




        return (
            <Row style={{}}>
                <Col span={23} style={{ padding: 20 }}>
                    <SignedInHeader logout={this.props.logout} userInfo={userInfo} />
                </Col>
                <Col span={24}>
                    <Row align="middle">
                        <Col span={24} style={{ minHeight: '100vh', padding: screenWidth === 'xs' ? 10 : 0 }}>
                            <Routes>
                                <Route path="/" element={<MainGallery uid={uid} type="main" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} />} />
                                <Route path="/my-gallery" element={<MyGallery uid={uid} type="my" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} />} />
                                <Route path="/upload" element={<Upload uid={uid} userInfo={userInfo} openSnack={this.openSnack} />} />
                                <Route path="/terms" element={<Terms uid={uid} />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/about" element={<AboutPage type="none" about={about} />} />
                                <Route path="/roadmap" element={<RoadMap type="none" pageInfo={roadMap} />} />
                                <Route path="/pricing" element={<Pricing type="none" screenWidth={screenWidth} />} />

                                <Route path="/signUp" element={<MainGallery uid={uid} type="main" screenWidth={screenWidth} userInfo={userInfo} openSnack={this.openSnack} />} />

                                <Route path='/profile/:id' element={<Profile uid={uid} logout={this.props.logout} openSnack={this.openSnack} userInfo={userInfo} screenWidth={screenWidth} />} />





                            </Routes>
                        </Col>

                    </Row>
                </Col>
                <Col span={23} style={{ height: 200, paddingTop: 30 }}>
                    <Row>

                        <Col span={24}>
                            <FooterBlock />
                        </Col>
                    </Row>
                </Col>

                <Snackbar open={openSnack} autoHideDuration={3000} onClose={this.closeSnack} >
                    <Alert severity={snackType} variant="filled">
                        {snackDescription}
                    </Alert>
                </Snackbar>

            </Row>
        );
    }
} 
