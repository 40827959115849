import React, { useEffect, useState } from 'react';
import { Col, Drawer, Modal, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Box, TextareaAutosize, Paper, Typography, Divider, Grid, IconButton, CardActions, CardContent, CircularProgress, Switch, Fab } from '@mui/material';


import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';


import { InstantSearch, RefinementList, SearchBox, Hits, Highlight, Pagination, useHits, Configure, InfiniteHits, useInfiniteHits, Menu, } from 'react-instantsearch-hooks-web';
import { DeleteForever, DownloadingOutlined, Favorite, FavoriteBorder, FavoriteOutlined, ImageNotSupportedOutlined, KeyboardArrowLeft, KeyboardArrowRight, RemoveRedEye, ThirtyFpsSelect } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getFirestore, arrayUnion, arrayRemove, updateDoc, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { async } from '@firebase/util';
import uuid from 'react-uuid';
import axios from 'axios';
import FileSaver, { saveAs } from 'file-saver';
import { useNavigate, useParams } from 'react-router-dom';

import { useSwipeable } from 'react-swipeable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { ReactPhotoCollage } from "react-photo-collage";
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import * as htmlToImage from 'html-to-image';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import download from 'downloadjs';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ScrollToTop from "react-scroll-to-top";
import SearchAll from './searchAll';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Gallery from '../pages/gallery';

var moment = require('moment-timezone');



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}






export default class CollectionView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            uid: this.props.uid,
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            card: null,
            collagePhotos: [],
            isCollageOpen: false,
            item: this.props.item,
            saving: false,
            value: 0,
            props: this.props,
            isAuthedUser: this.props.isAuthedUser,
            collectionImages: [],
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.uid !== prevProps.uid) {
            this.setState({ uid: this.props.uid })
        }
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }
        if (this.props.isAuthedUser !== prevProps.isAuthedUser) {
            this.setState({ isAuthedUser: this.props.isAuthedUser })
        }
        if (this.props.item !== prevProps.item) {
            console.log(this.props.item.images)
            this.setState({ item: this.props.item, collectionImages: this.props.item.images })
        }


    }
    componentDidMount() {

        const { uid, userInfo, item } = this.state;
        const key = uuid()
        console.log(item)
        this.setState({ collectionImages: item.images })

    }

    setCurrentCard = (card) => {
        this.setState({ currentCard: card })

    }

    updateCollectionImages = (images) => {
        this.setState({ item: { ...this.state.item, images: images } })
        console.log(images)


    }

    updateItem = (boo, val) => {
        let item = this.state.item;
        item[boo] = val;
        console.log(item)
        this.setState({ item: item });

    }

    handleChange = (newValue) => {
        this.setState({ value: newValue })
    }

    render() {

        const { uid, type, screenWidth, userInfo, collagePhotos, isCollageOpen, item, saving, value, isAuthedUser, collectionImages } = this.state;


        const UpdateCollectionButton = () => {
            const db = getFirestore();

            return (
                saving ? <CircularProgress /> : <Button style={{
                    fontFamily: 'Inter', textTransform: 'none', backgroundColor: '#3b5998',
                    color: 'white', fontWeight: 700
                }}
                    _active={{
                        bg: 'gray.800',
                        transform: 'scale(0.95)'
                    }} onClick={async () => {
                        this.setState({ saving: true })
                        if (item.name.length > 0) {
                            if (item.images.length > 0) {


                                const docRef = doc(db, 'items', item.images[0])
                                const docSnap = await getDoc(docRef);
                                this.props.updateHits(item)

                                if (docSnap.exists()) {

                                    item.url = docSnap.data().url;


                                    await updateDoc(doc(db, "collections", item.key), item).then(() => {
                                        this.props.openSnack('Collection Updated!', 'success')


                                    }).then(() => {
                                        //if last image return to my-gallery
                                        this.props.openSnack('success', 'Collection Updated!')
                                        this.setState({ saving: false })
                                        this.props.updateHits(item)
                                    }).then(() => {
                                        this.props.closeModal()
                                    })
                                } else {
                                    // doc.data() will be undefined in this case
                                    console.log("No such document!");
                                }


                            } else {
                                this.props.openSnack('error', 'Please add images to your collection!')
                                this.setState({ saving: false })
                            }
                        }
                        else {
                            this.props.openSnack('error', 'Please give your collection a name!')
                            this.setState({ saving: false })
                        }
                    }}

                >Update Collection</Button>

            )
        }


        const sidePadding = 0;
        console.log(ImageNotSupportedOutlined)


        return (
            <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 0 : 30, paddingTop: 20, paddingBottom: 30, paddingRight: screenWidth === 'xs' ? 0 : 30, }}>
                <Row gutter={[8, 8]}>


                    <Col span={24}>
                        <Row >
                            {isAuthedUser === true && (<Col xs={16} style={{ paddingTop: 30, paddingBottom: 15, }}>
                                <Typography variant="h4">Update a collection!</Typography>
                            </Col>)}
                            {isAuthedUser === true && (<Col xs={8} style={{ paddingTop: 30, paddingBottom: 15, textAlign: 'right' }}>
                                <UpdateCollectionButton />
                            </Col>)}


                            <Col span={24} style={{ paddingTop: 20 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography variant="h6">Name<span style={{ color: 'red' }}>*</span></Typography>
                                    </Col>
                                    <Col span={24} >
                                        {this.props.type === 'main' ||  isAuthedUser === false ? <Typography variant="body1">{item.name}</Typography> :
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Name"
                                                size="large"
                                                onChange={(e) => {
                                                    this.updateItem('name', e.target.value)
                                                }}
                                                value={item.name}
                                                fullWidth
                                                style={{
                                                    width: '100%', fontSize: 18, padding: 12, border: '1px solid #ccc', borderRadius: 5,
                                                    color: 'black', backgroundColor: 'white', fontFamily: 'inter'
                                                }}
                                            />}

                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24} style={{ paddingTop: 20 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography variant="h6">Description (optional)</Typography>
                                    </Col>
                                    <Col span={24}>
                                        {this.props.type === 'main' ||  isAuthedUser === false ? <Typography variant="body1">{item.description}</Typography> :
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Description"
                                                size="large"
                                                value={item.description}
                                                onChange={(e) => {
                                                    this.updateItem('description', e.target.value)
                                                }}
                                                fullWidth
                                                style={{
                                                    width: '100%', fontSize: 18, padding: 12, border: '1px solid #ccc', borderRadius: 5,
                                                    color: 'black', backgroundColor: 'white', fontFamily: 'inter'
                                                }}
                                            />}

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} style={{ paddingTop: 20 }} >
                                <Row>
                                    <Col span={24}>
                                        <Typography variant="h6">Images: {item.images?.length}</Typography>
                                    </Col>

                                </Row>
                            </Col>



                        </Row>
                    </Col>

                    <Col span={24} style={{ padding: screenWidth === 'xs' ? 0 : 0 }}>
                        <Box sx={{ width: '100%', fontFamily: 'Inter', padding: 0 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: 0 }}>
                                <Tabs value={value} variant="scrollable"
                                    scrollButtons="auto" onChange={(e, newValue) => {
                                        this.handleChange(newValue)
                                    }} aria-label="basic tabs example">
                                    <Tab label="Current" {...a11yProps(0)} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />
                                    {isAuthedUser && (<Tab label="Add photos" {...a11yProps(1)} style={{ paddingLeft: sidePadding, paddingRight: sidePadding, textTransform: 'none' }} />)}



                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0} style={{ padding: 0 }} sx={{ padding: 0 }}>


                                <Gallery type={this.props.type} screenWidth={screenWidth} userInfo={userInfo} openSnack={this.props.openSnack} index="itemSearch" item={item}
                                    filter="collections" collectionImages={item.images} tools={['collage',]} isAuthedUser={isAuthedUser}

                                />

                            </TabPanel>
                            <TabPanel value={value} index={1}>


                                <SearchAll uid={uid} type={type} setCurrentCard={this.setCurrentCard} screenWidth={screenWidth} userInfo={userInfo} item={item}
                                    openSnack={this.props.openSnack} updateCollectionImages={this.updateCollectionImages} isCollageOpen={isCollageOpen} isAuthedUser={isAuthedUser}
                                />


                            </TabPanel>
                        </Box>
                    </Col>






                </Row>




            </Col >

        );
    }
} 