import React from 'react';
import { Col, Row } from 'antd';
import { Breadcrumbs, Button, OutlinedInput, Paper, Typography, Divider, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Gallery from '../private/pages/gallery';
import CollectionsGallery from '../private/user/collectionsGallery';



export default class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: this.props.screenWidth,
            userInfo: this.props.userInfo,
            galleryType: 'images', // images or collections
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.screenWidth !== prevProps.screenWidth) {
            this.setState({ screenWidth: this.props.screenWidth })
        }
        if (this.props.userInfo !== prevProps.userInfo) {
            this.setState({ userInfo: this.props.userInfo })
        }


    }
    componentDidMount() {
        window.scrollTo(0, 0)

        console.log(this.props.userInfo)
    }

    render() {

        const { account, uid, type, screenWidth, galleryType } = this.state;
        console.log(galleryType)

        return (
            <Row style={{}} >
                <Col span={24}>

                    <Row align="middle" >
                        <Col xs={24} style={{ paddingLeft: screenWidth === 'xs' ? 5 : 30, fontFamily: 'Inter', }}>
                            <Typography variant={screenWidth === 'xs' ? 'h3' : 'h1'} fontWeight={600} >Welcome</Typography>
                        </Col>
                        <Col xs={24} style={{ paddingLeft: screenWidth === 'xs' ? 5 : 30, paddingTop: 15, paddingBottom: 15, fontFamily: 'Inter' }}>
                            <Typography variant="h6" style={{ color: 'rgba(0,0,0,0.5)' }}>Get Inspired. Build your profile. Explore AI design work.</Typography>
                        </Col>



                    </Row>

                </Col >

                <Col span={24} style={{ paddingLeft: screenWidth === 'xs' ? 5 : 30, fontFamily: 'Inter', }}>

                    <ToggleButtonGroup
                        style={{ textTransform: 'none', fontFamily: 'Inter', fontWeight: 700, fontSize: 12 }}
                        color="primary"
                        aria-label="Platform"
                        value={galleryType}
                        onChange={(e, newAlignment) => {
                            this.setState({ galleryType: e.target.value })
                        }}
                    >
                        <ToggleButton value="images" style={{ textTransform: 'none', fontFamily: 'Inter', fontWeight: 700, fontSize: 12 }} >Images</ToggleButton>
                        <ToggleButton value="collections" style={{ textTransform: 'none', fontFamily: 'Inter', fontWeight: 700, fontSize: 12 }} >Collections</ToggleButton>
                    </ToggleButtonGroup>

                </Col>

                {galleryType === 'images' && (
                    <Gallery type={'main'} openSnack={this.props.openSnack} screenWidth={screenWidth} index="itemSearch"
                        tools={['collage',]} />)}
                {galleryType === 'collections' && (<CollectionsGallery type={'main'} screenWidth={screenWidth} userInfo={this.props.userInfo} openSnack={this.props.openSnack} index="collectionsSearch" tools={['collage',]} />)}





            </Row >
        );
    }
} 